import { useEffect, useState } from "react";
import { sitesLogsListState, totalSitesLogsCountState } from "../../atom/audit";
import { useRecoilState } from "recoil";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { Skelton } from "../shared/skelton";
import { format } from "date-fns";

export const SiteLogTable = () => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const startIndex = (page - 1) * limit + 1;

  //recoil state
  const [siteLogsList, setSiteLogsList] = useRecoilState(sitesLogsListState);
  const [totalSitesLogs, setTotalSitesLogs] = useRecoilState(
    totalSitesLogsCountState
  );

  useEffect(() => {
    const getSitesLogs = async () => {
      setLoading(true);
      await Http.request({
        url: `${process.env.REACT_APP_API_URL}audit/sites-logs?page=${page}&limit=${limit}&site=${searchTerm}`,
        method: "get",
        headers: apiHeaders(),
      })
        .then((response) => {
          setSiteLogsList(response.data.sitesLogs);
          setTotalSitesLogs(response.data.total);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setSiteLogsList([]);
          if (error.response) {
            console.error("Server Error:", error.response.status);
          } else if (error.request) {
            console.error("Network Error:", error.request);
          } else {
            console.error("Error:", error.message);
          }
        });
    };

    getSitesLogs();
  }, [page, limit, searchTerm]);

  const searchSiteByName = async (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleLimitChange = (newLimit: any) => {
    setLimit(newLimit);
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Sites Log list</h5>
        <div className="datatable-wrapper datatable-loading card-footer sortable searchable fixed-columns">
          <div className="datatable-top">
            <div className="datatable-dropdown"></div>
            <div className="datatable-search">
              <input
                className="datatable-input search-box"
                placeholder="Search..."
                type="search"
                title="Search within table"
                onChange={searchSiteByName}
              />
            </div>
          </div>
          <div className="datatable-container table-responsive">
            <table className="table datatable datatable-table table-hover">
              <thead>
                <tr>
                  <th scope="col">S.No.</th>
                  <th scope="col">Current Name</th>
                  <th scope="col">Previous Name</th>
                  <th scope="col">Code</th>
                  <th scope="col">Address</th>
                  <th scope="col">Latitude</th>
                  <th scope="col">Longitude</th>
                  <th scope="col">Updated By</th>
                  <th scope="col">Updated On</th>
                </tr>
              </thead>
              <tbody>
                {loading && <Skelton totalCol={9}></Skelton>}
                {!loading && siteLogsList.length === 0 && (
                  <tr>
                    <td colSpan={12}>Sites Logs not found.</td>
                  </tr>
                )}
                {!loading &&
                  siteLogsList.length > 0 &&
                  siteLogsList.map((site: any, index: number) => {
                    return (
                      <tr key={index + 1}>
                        <td>{startIndex + index}</td>
                        <td>{site.updatedSite.siteName}</td>
                        <td>{site.siteName}</td>
                        <td>{site.siteCode}</td>
                        <td>{site.address}</td>
                        <td>{site.siteLat.toFixed(6)}</td>
                        <td>{site.siteLong.toFixed(6)}</td>
                        <td>{site.updatedSiteBy.firstName + " " + site.updatedSiteBy.lastName}</td>
                        <td>{format(site.updatedTimeDate, "PP,p")}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {totalSitesLogs > limit && (
              <div className="row">
                <div className="col-lg-6"></div>
                <div className="col-lg-3 pagination-select-box">
                  <span className="pagination-span">Rows per page:</span>
                  <select
                    className="pagination-select"
                    onChange={(e) => handleLimitChange(Number(e.target.value))}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                  </select>
                </div>
                <div className="col-lg-3 pagination-box">
                  <div className="pagination-num">
                    {(page - 1) * limit + 1} -{" "}
                    {Math.min(page * limit, totalSitesLogs)} of {totalSitesLogs}
                  </div>
                  <button
                    className={`btn pagination-btn`}
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 1}
                  >
                    <i className="bi bi-chevron-left"></i>
                  </button>
                  <button
                    className={`btn pagination-btn`}
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page * limit >= totalSitesLogs}
                  >
                    <i className="bi bi-chevron-right"></i>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
