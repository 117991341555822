import { atom } from "recoil";

export const toggleGateDeleteModalState = atom({
  key: "toggleGateDeleteModalState",
  default: false,
});

export const gateIdState = atom({
  key: "gateIdState",
  default: "",
});

export const gateListState = atom({
  key: "gateListState",
  default: [],
});

export const totalGatesState = atom({
  key: 'totalGatesState',
  default: 0,
});
export const gateLocationState = atom({
  key: "gateLocationState",
  default: {
    lat: "",
    lon: "",
  },
});
export const toggleGateLocationModalState = atom({
  key: "toggleGateLocationModalState",
  default: false,
});

