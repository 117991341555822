import { atom } from "recoil";

export const activeMenuState = atom({
  key: "activeMenuState",
  default: "",
});

export const toggleToasterState = atom({
  key: "toggleToasterState",
  default: {
    isShow: false,
    data: {
      title: "",
      message: "",
    },
  },
});

export const userLoginSitesInfo = atom({
  key: "",
  default: [],
});


