import { useRecoilState, useSetRecoilState } from "recoil";
import {
  siteIdState,
  siteLocationState,
  sitesListState,
  toggleSiteDeleteModalState,
  toggleSiteLocationModalState,
  totalSitesCountState,
} from "../../atom/site";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Skelton } from "../shared/skelton";
import { apiHeaders } from "../../utility/generic";
import { Http } from "../../utility/http";
import { DeleteSiteModal } from "./site-delete-modal";
import { SiteLocation } from "./site-location";
import { getUserDetails } from "../../utility/jwt";

export const SiteTable = () => {
  const [sitesList, setSitesList] = useRecoilState(sitesListState);
  const [totalSitesCount, setTotalSitesCount] =
    useRecoilState(totalSitesCountState);
  const setSiteToggleDeleteModal = useSetRecoilState(
    toggleSiteDeleteModalState
  );
  const setToggleSiteLocationModal = useSetRecoilState(
    toggleSiteLocationModalState
  );
  const { user } = getUserDetails();
  const setSiteLocation = useSetRecoilState(siteLocationState);
  const setSiteId = useSetRecoilState(siteIdState);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const startIndex = (page - 1) * limit + 1;

  useEffect(() => {
    getSites();
  }, [page, limit, searchTerm]);
  // console.log("user", user);
  const getSites = async () => {
    setLoading(true);
    await Http.request({
      url: `${process.env.REACT_APP_API_URL}site?page=${page}&limit=${limit}&search=${searchTerm}`,
      method: "get",
      headers: apiHeaders(),
    })
      .then((response) => {
        setSitesList(response.data.site);
        // console.log(response.data.user);
        setTotalSitesCount(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setSitesList([]);
        if (error.response) {
          console.error("Server Error:", error.response.status);
        } else if (error.request) {
          console.error("Network Error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };

  const handleDeleteSuccess = () => {
    if (
      page !== 1 &&
      page * limit >= totalSitesCount &&
      sitesList.length === 1
    ) {
      setPage(page - 1);
    }
    getSites();
  };

  const searchDeviceByName = async (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleLimitChange = (newLimit: any) => {
    setLimit(newLimit);
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Sites list</h5>
          <div className="datatable-wrapper datatable-loading card-footer sortable searchable fixed-columns">
            <div className="datatable-top">
              <div className="datatable-dropdown"></div>
              <div className="datatable-search">
                {!loading && user.role === "super_admin" && (
                  <Link to="/sites/add">
                    <button className="btn btn-primary mx-2">
                      <i className="bi bi-plus-lg"></i> Add Site
                    </button>
                  </Link>
                )}

                <input
                  className="datatable-input search-box"
                  placeholder="Search..."
                  type="search"
                  title="Search within table"
                  onChange={searchDeviceByName}
                />
              </div>
            </div>
            <div className="datatable-container">
              <table className="table datatable datatable-table table-hover table-responsive">
                <thead>
                  <tr>
                    <th scope="col">S.No.</th>
                    <th scope="col">Action</th>
                    <th scope="col">Site Code</th>
                    <th scope="col">Site Name</th>
                    <th scope="col">Address</th>
                    <th scope="col">Location</th>
                  </tr>
                </thead>
                <tbody>
                  {loading && <Skelton totalCol={6}></Skelton>}
                  {!loading && sitesList.length === 0 && (
                    <tr>
                      <td colSpan={6}>Sites not found.</td>
                    </tr>
                  )}
                  {!loading &&
                    sitesList.length > 0 &&
                    sitesList.map((site: any, index: number) => {
                      return (
                        <tr key={site.id}>
                          <td>{startIndex + index}</td>
                          <td>
                            <Link to={`/sites/edit/${site.id}`}>
                              <button className="btn btn-primary me-1">
                                <i className="bi bi-pencil-square"></i>
                              </button>
                            </Link>
                            {/* {!loading && user.role === "super_admin" && (
                              <Link to={``}>
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() => {
                                    setSiteId(site.id);
                                    setSiteToggleDeleteModal(true);
                                  }}
                                >
                                  <i className="bi bi-trash"></i>
                                </button>
                              </Link>
                            )} */}
                          </td>
                          <td>{site.siteCode}</td>
                          <td>{site.siteName}</td>
                          <td>{site.address}</td>
                          <td className="text-center">
                            <button
                              className="btn"
                              type="button"
                              onClick={() => {
                                setToggleSiteLocationModal(true);
                                setSiteLocation({
                                  lat: site.siteLat,
                                  lon: site.siteLong,
                                });
                              }}
                            >
                              <i className="bi bi-geo-fill"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {sitesList.length !== 0 && (
                <div className="row">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-3 pagination-select-box">
                    <span className="pagination-span">Rows per page:</span>
                    <select
                      className="pagination-select"
                      onChange={(e) =>
                        handleLimitChange(Number(e.target.value))
                      }
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                  <div className="col-lg-3 pagination-box">
                    <div className="pagination-num">
                      {(page - 1) * limit + 1} -{" "}
                      {Math.min(page * limit, totalSitesCount)} of{" "}
                      {totalSitesCount}
                    </div>
                    <button
                      className={`btn pagination-btn`}
                      onClick={() => handlePageChange(page - 1)}
                      disabled={page === 1}
                    >
                      <i className="bi bi-chevron-left"></i>
                    </button>
                    <button
                      className={`btn pagination-btn`}
                      onClick={() => handlePageChange(page + 1)}
                      disabled={page * limit >= totalSitesCount}
                    >
                      <i className="bi bi-chevron-right"></i>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <DeleteSiteModal onDeleteSuccess={handleDeleteSuccess} />
      <SiteLocation></SiteLocation>
    </>
  );
};
