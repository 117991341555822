// @ts-nocheck
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Http } from "../utility/http";
import { AlterError } from "../components/shared/alter-error";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate, Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { AuthenticationContext } from "react-adal";
import jwtDecode from "jwt-decode";
import logo from "../assets/images/Adani_2012_logo.png.webp";
import { MathCaptcha } from "../components/captcha/math-captcha";

interface ILoginForm {
  email: string;
  password: string;
}
interface AdFSConfig {
  clientId: string;
  tenant: string;
  endpoints: {
    api: string;
  };
  redirectUri: string;
}
export const Login = () => {
  const config: AdFSConfig = {
    clientId: process.env.REACT_APP_ADFS_APP_ID,
    tenant: process.env.REACT_APP_ADFS_TENANT_ID,
    endpoints: {
      api: process.env.REACT_APP_ADFS_LOGIN_URL,
    },
    redirectUri: process.env.REACT_APP_ADFS_REDIRECT_URL,
  };
  const authContext = new AuthenticationContext(config);

  const [adfsAuthenticated, setAdfsAuthenticated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    console.log("authContext", authContext);
    if (authContext.getCachedUser()) {
      const user = authContext.getCachedUser();
      console.log("user", user);
      setAdfsAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    const token = Cookies.get("token");
    let urlHash = window.location?.hash;
    if (urlHash && !token) {
      urlHash = urlHash.replace("#id_token=", "");
      console.log(urlHash);
      const urlHashArr = urlHash.split("&");
      //console.log(urlHashArr[0]);
      if (urlHashArr.length) {
        try {
          const decoded = jwtDecode(urlHashArr[0]);
          console.log(decoded);
          const payLoad = {
            firstName: decoded?.given_name || "",
            lastName: decoded?.family_name || "",
            email: decoded?.unique_name || "",
          };
          Http.request({
            url: `${process.env.REACT_APP_API_URL}auth/register`,
            method: "post",
            data: payLoad,
          })
            .then((result) => {
              Cookies.set("token", result.data.accessToken);
              setIsLoading(false);
              navigate("/dashboard");
            })
            .catch((error) => {
              setIsLoading(false);
              console.log("error", error);
              if (error.response.status === 401) {
                setInValidLoginError(error.response.data.message);
              }
            });
          console.log(payLoad);
        } catch (e) {
          setInValidLoginError("Something went wrong!");
        }
      } else {
        setInValidLoginError("Something went wrong!");
      }
    }
  }, []);

  const adFSlogin = () => {
    authContext.login();
  };

  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email-id is required."),
    password: Yup.string().required("Password is required."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginForm>({
    resolver: yupResolver(validationSchema),
  });

  const [inValidLoginError, setInValidLoginError] = useState("");
  const navigate = useNavigate();
  const onSubmitHandler = (data: ILoginForm) => {
    if (isValid) {
      setInValidLoginError("");
      setIsLoading(true);
      Http.request({
        url: `${process.env.REACT_APP_API_URL}auth/login`,
        method: "post",
        data: data,
      })
        .then((result) => {
          Cookies.set("token", result.data.accessToken);
          setIsLoading(false);
          navigate("/dashboard");
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("error", error);
          if (error.response.status) {
            setInValidLoginError(error.response.data.message);
            setTimeout(() => {
              setInValidLoginError("");
            }, 15000);
          }
        });
    }
  };

  return (
    <>
      <main>
        <div className="container">
          <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div className="d-flex justify-content-center pt-4">
                    <Link
                      to="/dashboard"
                      className="logo d-flex align-items-center w-auto"
                    >
                      <img
                        src={logo}
                        style={{ maxHeight: "120px" }}
                        alt="logo"
                      />
                    </Link>
                  </div>
                  {inValidLoginError && (
                    <AlterError message={inValidLoginError}></AlterError>
                  )}

                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="pt-4 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">
                          Login to Your Account
                        </h5>
                        {/* <p className="text-center small">
                          Enter your email & password to login
                        </p> */}
                      </div>

                      <form
                        className="row g-3 needs-validation"
                        onSubmit={handleSubmit(onSubmitHandler)}
                      >
                        <div className="col-12">
                          <label htmlFor="yourUsername" className="form-label">
                            Email
                          </label>
                          <div className="input-group has-validation">
                            <span
                              className="input-group-text"
                              id="inputGroupPrepend"
                            >
                              @
                            </span>
                            <input
                              type="text"
                              {...register("email")}
                              className="form-control"
                              id="yourUsername"
                            />

                            <div className="invalid-feedback">
                              {errors.email?.message}
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <label htmlFor="yourPassword" className="form-label">
                            Password
                          </label>
                          <div className="input-group has-validation">
                            <input
                              type={showPassword ? "text" : "password"}
                              {...register("password")}
                              className="form-control"
                              id="yourPassword"
                              required
                            />
                            <span
                              className={`input-group-text ${
                                showPassword
                                  ? "bi bi-eye-slash-fill"
                                  : "bi bi-eye-fill"
                              }`}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                setShowPassword((prevState) => !prevState)
                              }
                            ></span>
                            <div className="invalid-feedback">
                              {errors.password?.message}
                            </div>
                          </div>
                          <div className="d-flex justify-content-end small mt-2">
                            <Link to={"/forget-password"} className="">
                              Forget Password?
                            </Link>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="remember"
                              value="true"
                              id="rememberMe"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="rememberMe"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>

                        <div className="col-12">
                          <MathCaptcha
                            isValid={isValid}
                            setIsValid={setIsValid}
                          />
                        </div>

                        <div className="col-12 ps-0">
                          <button
                            type="submit"
                            className="btn btn-primary mx-2 w-100"
                            disabled={isLoading || !isValid}
                          >
                            {isLoading ? (
                              <Spinner
                                className="me-1"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Login"
                            )}
                          </button>
                        </div>
                        {/* <div className="col-12">
                          <p className="small mb-0">
                            Don't have account?
                            <a href="pages-register.html">Create an account</a>
                          </p>
                        </div> */}
                      </form>
                      <br />
                      <div className="row g-3">
                        <div className="col-12 mt-2 ps-0">
                          <button
                            className="btn btn-primary mx-2 w-100"
                            onClick={adFSlogin}
                            disabled={isLoading || !isValid}
                          >
                            Adani ID
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};
