interface Props {
    message: string;
}

const SuccessMessage = ({ message }: Props) => {
    return (
        <>
            <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                {message}
            </div>
        </>
    )
}



export default SuccessMessage;