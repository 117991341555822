import React from "react";
import { MainLayout } from "../../layout/main-layout";
import { ProfileSection } from "../../components/profile/profile-section";
import Breadcrumb from "react-bootstrap/esm/Breadcrumb";
import { useSetRecoilState } from "recoil";
import { activeMenuState } from "../../atom/generic";
import { Toaster } from "../../components/shared/toaster";

const UserProfile: React.FC = () => {
  const setActiveMenu= useSetRecoilState(activeMenuState);
  setActiveMenu('profile');
  return (
    <>
      <MainLayout>
        {/* Profile Section  */}
        <div className="">
          <h2
            className="pagetitle"
            style={{
              color: "#012970",
              fontWeight: "600",
              marginBottom: 0,
              fontSize: "24px",
              fontFamily: "Nunito, sans-serif",
            }}
          >
            Profile
          </h2>
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>

            <Breadcrumb.Item active>Profile</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <ProfileSection />
        {/* End Profile Section  */}
      </MainLayout>
      <Toaster />
    </>
  );
};

export default UserProfile;
