interface Props {
  message: string;
}
export const AlterError = ({ message }: Props) => {
  return (
    <>
      <div
        className="custom-alert alert alert-danger bg-danger text-light border-0 alert-dismissible fade show"
        role="alert"
        
      >
        {message}
        {/* <button
          type="button"
          className="btn-close btn-close-white"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button> */}
      </div>
    </>
  );
};
