import { Breadcrumb } from "react-bootstrap";
import { MainLayout } from "../../layout/main-layout";
import { EditSiteFormSection } from "../../components/sites/edit-site-form";

export const EditSitePage = () => {
  return (
    <>
      <MainLayout>
        <div className="pagetitle">
          <h1>Edit</h1>
        </div>
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item href="/sites">Sites</Breadcrumb.Item>
          <Breadcrumb.Item active>Edit</Breadcrumb.Item>
        </Breadcrumb>
        <EditSiteFormSection />
      </MainLayout>
    </>
  );
};
