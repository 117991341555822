import { useSetRecoilState } from "recoil";
import { MainLayout } from "../../layout/main-layout";
import { activeMenuState } from "../../atom/generic";
import Breadcrumb from "react-bootstrap/esm/Breadcrumb";
import { SettingForm } from "../../components/setting/account-setting";



export const Setting = () => {
  const setActiveMenu= useSetRecoilState(activeMenuState);
  setActiveMenu('setting');
  return (
    <>
      <MainLayout>
      <div className="pagetitle">
      <h2 className="pagetitle" style={{ color: '#012970', fontWeight: '600', marginBottom: 0, fontSize: '24px', fontFamily: "Nunito, sans-serif" }} >Edit Setting</h2>
        <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item href="/dashboard">Dashborad</Breadcrumb.Item>           
            <Breadcrumb.Item active>Setting</Breadcrumb.Item>
          </Breadcrumb>
      </div>
        <SettingForm></SettingForm>
      </MainLayout>
    </>
  );
};
