import { useSetRecoilState } from "recoil";
import { GateForm } from "../../components/gate/add-gate-form";
import { MainLayout } from "../../layout/main-layout";
import { activeMenuState } from "../../atom/generic";
import Breadcrumb from "react-bootstrap/esm/Breadcrumb";

export const AddGate = () => {
  const setActiveMenu = useSetRecoilState(activeMenuState);
  setActiveMenu("gate");
  return (
    <>
      <MainLayout>
      <div className="pagetitle">
        <h1>Add Gate</h1>
        <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item href="/gates">Gates</Breadcrumb.Item>
            <Breadcrumb.Item active>Add</Breadcrumb.Item>
          </Breadcrumb>
      </div>
        <GateForm></GateForm>
      </MainLayout>
    </>
  );
};
