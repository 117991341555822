export const Footer = () => {
  return (
    
    <footer id="footer" className="footer" >
      <div className="copyright" >
        © Copyright{" "}
        <strong>
          <span>Adani</span>
        </strong>
        . All Rights Reserved
      </div>
    </footer>
    
  );
};
