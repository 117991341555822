import { Modal, Button } from "react-bootstrap";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useState } from "react";
// @ts-expect-error
import DatePicker from "react-datepicker";
import { add } from "date-fns";

import "react-datepicker/dist/react-datepicker.css";
import {
  dateRangeValueState,
  toggleCalendarModalState,
} from "../../../atom/trip";

export default function CalendarModal() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(add(new Date(), { days: 1 }));
  const [toggleCalendarModal, setToggleCalendarModal] = useRecoilState(
    toggleCalendarModalState
  );
  const setDateRangeValue = useSetRecoilState(dateRangeValueState);

  const handleClose = () => {
    setToggleCalendarModal(false);
  };

  const setDateRange = () => {
    setDateRangeValue((prevState) => ({
      ...prevState,
      start: startDate.toISOString(),
      end: endDate.toISOString(),
    }));
    handleClose();
  };

  return (
    <>
      <Modal show={toggleCalendarModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Date Range</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "6px" }}
          >
            <div>
              <label style={{ fontWeight: 500 }}>Start Date: </label>
              <div className="p-0">
                <DatePicker
                  selected={startDate}
                  onChange={(date: any) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                />
              </div>
            </div>
            <div>
              <label style={{ fontWeight: 500 }}>End Date: </label>
              <div>
                <DatePicker
                  selected={endDate}
                  onChange={(date: any) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={setDateRange}>
            Set Range
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
