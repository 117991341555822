import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  toggleUserDeleteModalState,
  userIdState,
  usersListState,
} from "../../atom/users";
import { apiHeaders } from "../../utility/generic";
import { Http } from "../../utility/http";
import { Button, Modal } from "react-bootstrap";

type DeleteSuccessProps = {
  onDeleteSuccess: Function;
};

export const DeleteUserModal = ({ onDeleteSuccess }: DeleteSuccessProps) => {
  const [toggleUserDeleteModal, setToggleUserDeleteModal] = useRecoilState(
    toggleUserDeleteModalState
  );
  const setUsersList = useSetRecoilState(usersListState);
  const userId = useRecoilValue(userIdState);
  const deleteUser = async () => {
    await Http.request({
      url: `${process.env.REACT_APP_API_URL}users/${userId}`,
      method: "delete",
      headers: apiHeaders(),
    })
      .then(() => {
        setUsersList((prevState: any) => {
          const users: any = [...prevState];
          const index = users.findIndex((user: any) => user.id === userId);
          if (index !== -1) {
            users.splice(index, 1);
          }
          return users;
        });
        setToggleUserDeleteModal(false);
        onDeleteSuccess();
      })
      .catch((error) => {
        if (error.response) {
          console.error("Server Error:", error.response.status);
        } else if (error.request) {
          console.error("Network Error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };
  return (
    <>
      <Modal
        show={toggleUserDeleteModal}
        onHide={() => {
          setToggleUserDeleteModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete user</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setToggleUserDeleteModal(false);
            }}
          >
            No
          </Button>
          <Button variant="primary" onClick={deleteUser}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
