import Cookie from "js-cookie";
import { AxiosRequestHeaders } from "axios";
export const apiHeaders = (): AxiosRequestHeaders => {
  // @ts-expect-error
  const headers: AxiosRequestHeaders = {
    Authorization: `Bearer ${Cookie.get("token")}`,
  };
  return headers;
};

export const formatDurationToTimeFormat = (duration: number): string => {
  // Convert total duration to hours and minutes
  var hours = Math.floor(duration / 60);
  var minutes = Math.floor(duration % 60);

  // Format hours and minutes
  var formattedHours = hours < 10 ? "0" + hours : hours;
  var formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  // Return formatted duration as hh:mm
  return formattedHours + ":" + formattedMinutes;
};
