import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { Spinner } from "react-bootstrap";
import { useSetRecoilState } from "recoil";
import { toggleToasterState } from "../../atom/generic";
import { AlterError } from "../shared/alter-error";

type UpdateDeviceForm = {
  deviceName?: string;
  imeiNumber?: string;
  description?: string;
};

export const UpdateDeviceFormSection = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const setToggleToaster = useSetRecoilState(toggleToasterState);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmittedSuccessful , setIsSubmittedSuccessful] = useState(false);

  useEffect(() => {
    getOneDevice();
  }, []);
  const addDeviceSchema = Yup.object().shape({
    deviceName: Yup.string().optional(),
    imeiNumber: Yup.string().optional(),
    description: Yup.string().optional(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    setValue,
  } = useForm<UpdateDeviceForm>({
    resolver: yupResolver(addDeviceSchema),
  });

  const getOneDevice = async () => {
    await Http.request({
      url: `${process.env.REACT_APP_API_URL}device/${id}`,
      method: "get",
      headers: apiHeaders(),
    })
      .then((result) => {
        const data = result.data;
        setValue("deviceName", data.deviceName);
        setValue("imeiNumber", data.imeiNumber);
        setValue("description", data.description);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          console.log(error);
          setErrorMessage(error.response.data.message);
          setTimeout(() => {
            setErrorMessage("");
          }, 7000);
        }
      });
  };

  const saveChangesHandler = async (data: UpdateDeviceForm) => {
    setIsLoading(true);
    setIsSubmittedSuccessful(true);
    await Http.request({
      url: `${process.env.REACT_APP_API_URL}device/${id}`,
      method: "put",
      data: data,
      headers: apiHeaders(),
    })
      .then((result) => {
        setToggleToaster({
          isShow: true,
          data: {
            title: "Device",
            message: result.message,
          },
        });
        setTimeout(() => {
          navigate(-1);
          setIsLoading(false);
        }, 1500);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsSubmittedSuccessful(false);
        console.log(error);
        if (error.response.status >= 400 || error.response.status <= 500) {
          setErrorMessage(error.response.data.message);
        }
      });
  };

  return (
    <>
      {errorMessage && <AlterError message={"*" + errorMessage} />}
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Edit device</h5>
          <form onSubmit={handleSubmit(saveChangesHandler)}>
            <div className="row mb-3">
              <label htmlFor="deviceName" className="col-sm-2 col-form-label">
                Asset Id :
              </label>
              <div className="col-sm-10">
                <input
                  className={`form-control ${
                    errors.deviceName ? "is-invalid" : ""
                  }`}
                  {...register("deviceName")}
                  type="text"
                  id="inputDeviceName"
                />
                <div className="invalid-feedback">
                  {errors.deviceName?.message}
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="description" className="col-sm-2 col-form-label">
                IMEI No:
              </label>
              <div className="col-sm-10">
                <input
                  className={`form-control`}
                  {...register("imeiNumber")}
                  type="text"
                  id="inputDecription"
                />
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="description" className="col-sm-2 col-form-label">
                Description :
              </label>
              <div className="col-sm-10">
                <input
                  className={`form-control`}
                  {...register("description")}
                  type="text"
                  id="inputDecription"
                />
              </div>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary mx-2"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner
                    className="me-1"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Update"
                )}
              </button>
              {!isSubmitSuccessful && (
                <button
                  type="reset"
                  className="btn btn-secondary"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
