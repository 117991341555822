import { useEffect, useState } from "react";

type MathCaptchaProps = {
  isValid: boolean;
  setIsValid: any;
};

export const MathCaptcha = ({ isValid, setIsValid }: MathCaptchaProps) => {
  const [num1, setNum1] = useState(0);
  const [num2, setNum2] = useState(0);
  const [totalSum, setTotalSum] = useState(0);
  const [userAnswer, setUserAnswer] = useState("");
  //   const [captchaValid, setCaptchaValid] = useState(false);
  useEffect(() => {
    const newNum1 = Math.floor(Math.random() * 10) + 1;
    const newNum2 = Math.floor(Math.random() * 10) + 1;
    const newTotalSum = newNum1 + newNum2;

    setNum1(newNum1);
    setNum2(newNum2);
    setTotalSum(newTotalSum);
  }, []);

  const handleInputChange = (event: any) => {
    setUserAnswer(event.target.value);
    if (parseInt(event.target.value) === num2) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };
  return (
    <div style={{ padding: "0 20px" }}>
      {!isValid && (
        <>
       <p>Please solve the following math problem:</p>
          <p>
            {num1} + ? = {totalSum}
          </p>
          <input
            type="text"
            className="form-control"
            value={userAnswer}
            onChange={handleInputChange}
            placeholder="Enter your answer"
          />
        </>
      )}
      {isValid ? (
        <p style={{ color: "green", paddingTop: 5 }}>Captcha correct!</p>
      ) : null}
    </div>
  );
};
