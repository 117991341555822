import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { apiHeaders } from "../../utility/generic";
import { Http } from "../../utility/http";
import { Button, Modal } from "react-bootstrap";
import { siteIdState, sitesListState, toggleSiteDeleteModalState } from "../../atom/site";

type DeleteSuccessProps = {
  onDeleteSuccess: Function;
};

export const DeleteSiteModal = ({ onDeleteSuccess }: DeleteSuccessProps) => {
  const [toggleSiteDeleteModal, setToggleSiteDeleteModal] = useRecoilState(
    toggleSiteDeleteModalState
  );
  const setSitesList = useSetRecoilState(sitesListState);
  const siteId = useRecoilValue(siteIdState);

  const deleteSite = async () => {
    await Http.request({
      url: `${process.env.REACT_APP_API_URL}site/${siteId}`,
      method: "delete",
      headers: apiHeaders(),
    })
      .then(() => {
        setSitesList((prevState: any) => {
          const sites: any = [...prevState];
          const index = sites.findIndex((site: any) => site.id === siteId);
          if (index !== -1) {
            sites.splice(index, 1);
          }
          return sites;
        });
        setToggleSiteDeleteModal(false);
        onDeleteSuccess();
      })
      .catch((error) => {
        if (error.response) {
          console.error("Server Error:", error.response.status);
        } else if (error.request) {
          console.error("Network Error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };
  return (
    <>
      <Modal
        show={toggleSiteDeleteModal}
        onHide={() => {
          setToggleSiteDeleteModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete site</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete this site?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setToggleSiteDeleteModal(false);
            }}
          >
            No
          </Button>
          <Button variant="primary" onClick={deleteSite}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
