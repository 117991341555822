import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Http } from "../../utility/http";
import { useNavigate, useParams } from "react-router-dom";
import { apiHeaders } from "../../utility/generic";
import { useEffect, useState } from "react";
import { toggleToasterState } from "../../atom/generic";
import { useSetRecoilState } from "recoil";
import { Spinner } from "react-bootstrap";
import { AlterError } from "../shared/alter-error";
// import { AlterError } from "../shared/alter-error";

interface IEditGateForm {
  name: string;
  //description: string;
  latitude: number;
  longitude: number;
}
export const EditGateForm = () => {
  const [error, setError] = useState("");
  const setToggleToaster = useSetRecoilState(toggleToasterState);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Gate name is required."),
    // description: Yup.string().required("Description is required."),
    latitude: Yup.number().required("Latitude is required."),
    longitude: Yup.number().required("Longitude is required."),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm<IEditGateForm>({
    resolver: yupResolver(validationSchema),
  });
  const { id } = useParams();
  //   const [gateDetails, setGateDetails] = useState({
  //     name: "",
  //     latitude: "",
  //     longitude: "",
  //   });

  useEffect(() => {
    getGateDetails();
  });

  const cancel = () => {
    navigate("/gates");
  };

  const getGateDetails = () => {
    Http.request({
      url: `${process.env.REACT_APP_API_URL}gate/${id}`,
      method: "get",
      headers: apiHeaders(),
    })
      .then((result) => {
        //setGateDetails(result);
        setValue("name", result.name);
        setValue("latitude", result.latitude);
        setValue("longitude", result.longitude);
      })
      .catch((error) => {
        setIsLoading(false);

        if (error) {
          console.log(error.response.data.message);
          setError(error.response.data.message);
          setTimeout(() => {
            setError("");
          }, 9000);
        }
      });
  };

  const onSubmitHandler = (data: IEditGateForm) => {
    setIsLoading(true);
    Http.request({
      url: `${process.env.REACT_APP_API_URL}gate/${id}`,
      method: "put",
      data: data,
      headers: apiHeaders(),
    })
      .then((result) => {
        setToggleToaster({
          isShow: true,
          data: {
            title: "Gate",
            message: result.message,
          },
        });
        setTimeout(() => {
          navigate(-1);
          setIsLoading(false);
        }, 1500);
      })
      .catch((error) => {
        if (error) {
          console.log(error.response.data.message);
          setError(error.response.data.message);
          setTimeout(() => {
            setError("");
          }, 9000);
        }
        // setErrorMessage(error.response.data.message[0]);
        // setTimeout(() => {
        //   setErrorMessage("");
        // }, 7000);
      });
    //console.log(data)
  };

  return (
    <>
      {error && <AlterError message={error}></AlterError>}
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            {errorMessage && <AlterError message={"*" + errorMessage} />}
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Edit Gate</h5>

                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Gate Name
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="inputText"
                        {...register("name")}
                        placeholder="Enter Gate Name"
                      />
                      <p className="v-error">{errors.name?.message}</p>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Latitude
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="inputEmail"
                        {...register("latitude")}
                        placeholder="Enter Gate Latitude"
                      />
                      <p className="v-error">{errors.latitude?.message}</p>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Longitude
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="inputEmail"
                        {...register("longitude")}
                        placeholder="Enter Gate Longitude"
                      />
                      <p className="v-error">{errors.longitude?.message}</p>
                    </div>
                  </div>

                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary mx-2"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Spinner
                          className="me-1"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                    {!isSubmitSuccessful && !isLoading && (
                      <button
                        type="reset"
                        className="btn btn-secondary"
                        onClick={cancel}
                      >
                        Cancel
                      </button>
                    )}
                    {/* {errorMessage && !isSubmitSuccessful && (
                      <button
                        type="reset"
                        className="btn btn-secondary"
                        onClick={() => {
                          reset();
                        }}
                      >
                        Reset
                      </button>
                    )}
                    {errorMessage && (
                      <div className="container my-1 w-50 h-25 shadow-lg">
                        <AlterError message={errorMessage} />
                      </div>
                    )} */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
