import Breadcrumb from "react-bootstrap/esm/Breadcrumb";
import { AddUserForm } from "../../components/user/add-user-form";
import { MainLayout } from "../../layout/main-layout";
import { activeMenuState } from "../../atom/generic";
import { useSetRecoilState } from "recoil";


export const AddUser = () => {
  const setActiveMenu= useSetRecoilState(activeMenuState);
  setActiveMenu('user');

  return (
    <>
      <MainLayout>
      <div className="pagetitle">
        <h1>Add</h1>
        <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item href="/users">Users</Breadcrumb.Item>
            <Breadcrumb.Item active>Add</Breadcrumb.Item>
          </Breadcrumb>
      </div>
        <AddUserForm />
      </MainLayout>
    </>
  );
};
