import { useEffect, useState, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  gateIdState,
  gateListState,
  gateLocationState,
  toggleGateDeleteModalState,
  toggleGateLocationModalState,
  totalGatesState,
} from "../../atom/gate";
import { DeleteGate } from "./delete-gate";
import { Skelton } from "../shared/skelton";

import { toggleToasterState } from "../../atom/generic";
import { AlterError } from "../shared/alter-error";
import { GateLocation } from "./gate-location";

export const GateList = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const setToggleGateDeleteModal = useSetRecoilState(
    toggleGateDeleteModalState
  );
  const setToggleGateLocationModal = useSetRecoilState(
    toggleGateLocationModalState
  );
  const setGateId = useSetRecoilState(gateIdState);
  const [gateList, setGateList] = useRecoilState(gateListState);
  const [totalGates, setTotalGates] = useRecoilState(totalGatesState);
  const [toggleToaster, setToggleToaster] = useRecoilState(toggleToasterState);
  const setGateLocation = useSetRecoilState(gateLocationState);

  useEffect(() => {
    getGates();
  }, [page, limit, searchTerm]);

  const getGates = () => {
    setLoading(true);
    Http.request({
      url: `${process.env.REACT_APP_API_URL}gate?page=${page}&limit=${limit}&search=${searchTerm}`,
      method: "get",
      headers: apiHeaders(),
    })
      .then((result) => {
        setGateList(result.data.gates);
        console.log(result.data.gates);
        setTotalGates(result.data.total);

        setLoading(false);
      })
      .catch((error) => {
        if(error){
          console.log(error.response.data.message)
          setError(error.response.data.message)
          setTimeout(() => {
          setError("");
          }, 9000);
      }
        setLoading(false);
        setGateList([]);
        if (error.response.statusCode === 401) {
        }
      });
  };

  const handleDeleteSuccess = () => {
    // Adjust the page if needed
    if (page !== 1 && page * limit >= totalGates && gateList.length === 1) {
      setPage(page - 1);
    }
    // Fetch the updated data
    getGates();
  };

  const serachGate = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (newPage: SetStateAction<number>) => {
    setPage(newPage);
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Gates List</h5>
          {/* <p>
            Manage gate listing, searching, add new gate, edit gate and delete
            gate.
          </p> */}
          <div className="datatable-wrapper datatable-loading card-footer sortable searchable fixed-columns">
            <div className="datatable-top">
              <div className="datatable-dropdown"></div>
              <div className="datatable-search">
                <Link to="/gates/add">
                  <button className="btn btn-primary mx-2">
                    <i className="bi bi-plus-lg"></i> Add Gate
                  </button>
                </Link>
                <input
                  className="datatable-input search-box"
                  placeholder="Search..."
                  type="search"
                  title="Search within table"
                  onChange={serachGate}
                />
              </div>
            </div>
            <div className="datatable-container">
              <table className="table datatable datatable-table comman_table table-hover">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Action</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Location</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {loading && <Skelton></Skelton>}
                  {!loading && gateList.length === 0 && (
                    <tr>
                      <td colSpan={6}>Gates not found.</td>
                    </tr>
                  )}
                  {!loading &&
                    gateList.length > 0 &&
                    gateList.map((gate: any, index) => {
                      return (
                        <tr data-index="0" key={gate.id}>
                          <td>{(page - 1) * limit + 1 + index}</td>
                          <td>
                            <Link to={`/gates/edit/${gate.id}`}>
                              <button className="btn btn-primary me-1">
                                <i className="bi bi-pencil-square"></i>
                              </button>
                            </Link>
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => {
                                setGateId(gate.id);
                                setToggleGateDeleteModal(true);
                              }}
                            >
                              <i className="bi bi-trash"></i>
                            </button>
                          </td>
                          <td>{gate.name}</td>
                          <td>{gate.description}</td>
                          <td>
                          <button className="btn"
                              type="button"
                              onClick={() => {
                                setToggleGateLocationModal(true);
                                setGateLocation({
                                  lat: gate.latitude,
                                  lon: gate.longitude,
                                });
                              }}
                            >
                              <i className="bi bi-geo-fill"></i>
                            </button>
                           
                          </td>
                          <td>
                            {gate.status === "Active" ? (
                              <span className="badge rounded-pill bg-success">
                                Active
                              </span>
                            ) : (
                              <span className="badge rounded-pill bg-danger">
                                InActive
                              </span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {gateList.length > 0 && (
                <div className="row">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-3 pagination-select-box">
                    <div className={`${gateList.length < 5 ? "heddin" : ""}`}>
                      <span className="pagination-span">Rows per page:</span>
                      <select
                        className={`pagination-select`}
                        onChange={(e) => {
                          setLimit(Number(e.target.value));
                          setPage(1);
                        }}
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-3 pagination-box">
                    <div className="pagination-num">
                      {(page - 1) * limit + 1} -{" "}
                      {Math.min(page * limit, totalGates)}&nbsp; of &nbsp;
                      {totalGates}
                    </div>

                    <button
                      className={`btn pagination-btn`}
                      onClick={() => handlePageChange(page - 1)}
                      disabled={page === 1}
                    >
                      <i className="bi bi-chevron-left"></i>
                    </button>
                    <button
                      className={`btn pagination-btn`}
                      onClick={() => handlePageChange(page + 1)}
                      disabled={page * limit >= totalGates}
                    >
                      <i className="bi bi-chevron-right"></i>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <DeleteGate onDeleteSuccess={handleDeleteSuccess} />
      <GateLocation></GateLocation>
    </>
  );
};
