import { Modal, Button } from "react-bootstrap";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  deviceIdState,
  deviceListState,
  toggleDeviceDeleteModalState,
} from "../../atom/device";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";

type DeleteSuccessProps = {
  onDeleteSuccess: Function;
}

export const DeleteDeviceModal = ({onDeleteSuccess}:DeleteSuccessProps) => {
  const [toggleDeviceDeleteModal, setToggleDeviceDeleteModal] = useRecoilState(
    toggleDeviceDeleteModalState
  );
  const deviceId = useRecoilValue(deviceIdState);
  const setDeviceList = useSetRecoilState(deviceListState);

  const deleteDevice = async () => {
    await Http.request({
      url: `${process.env.REACT_APP_API_URL}device/${deviceId}`,
      method: "delete",
      headers: apiHeaders(),
    })
      .then(() => {
        setDeviceList((prevState: any) => {
          const devices: any = [...prevState];
          const index = devices.findIndex(
            (device: any) => device.id === deviceId
          );
          if (index !== -1) {
            devices.splice(index, 1);
          }
          return devices;
        });
        setToggleDeviceDeleteModal(false);
        onDeleteSuccess();
      })
      .catch((error) => {
        if (error.response) {
          console.error("Server Error:", error.response.status);
        } else if (error.request) {
          console.error("Network Error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };
  return (
    <>
      <Modal
        show={toggleDeviceDeleteModal}
        onHide={() => {
          setToggleDeviceDeleteModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete device</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete this device?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setToggleDeviceDeleteModal(false);
            }}
          >
            No
          </Button>
          <Button variant="primary" onClick={deleteDevice}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
