import { useSetRecoilState } from "recoil";
import { DeviceCard } from "../components/dashboard/cards/device-card";
import { GateCard } from "../components/dashboard/cards/gate-card";
import { LogisticCentreCard } from "../components/dashboard/cards/logistic-centre-card";
import { TruckCard } from "../components/dashboard/cards/truck-card";
import { UsersCard } from "../components/dashboard/cards/users-card";
import { DashBoardColumnChart } from "../components/dashboard/column-chart-dashboard";
// import { DashBoardLineChart } from "../components/dashboard/line-chart-dashboard";
import { DashboardTable } from "../components/dashboard/table-dashboard";
import { MainLayout } from "../layout/main-layout";
import { activeMenuState, userLoginSitesInfo } from "../atom/generic";
import { getUserDetails } from "../utility/jwt";
import { useEffect, useState } from "react";
import { Http } from "../utility/http";
import { apiHeaders } from "../utility/generic";
import { number, object } from "yup";

export const Dashboard = () => {
  const setActiveMenu = useSetRecoilState(activeMenuState);
  setActiveMenu("dashboard");
  const setTotalSitesByUser = useSetRecoilState(userLoginSitesInfo);
  const { user } = getUserDetails();
  const [statistics, setStatistics] = useState({
    totalUsers: 0,
    totalDevices: 0,
    totalTrucks: 0,
  });
  const [loading, setLoading] = useState(false);

  const getStatistics = async () => {
    setLoading(true);
    await Http.request({
      url: `${process.env.REACT_APP_API_URL}analytics/statistics`,
      method: "get",
      headers: apiHeaders(),
    })
      .then((response) => {
        setStatistics(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setStatistics({ totalUsers: 0, totalDevices: 0, totalTrucks: 0 });
        if (error.response) {
          console.error("Server Error:", error.response.status);
        } else if (error.request) {
          console.error("Network Error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };
  useEffect(() => {
    const getAdminUser = async () => {
      await Http.request({
        url: `${process.env.REACT_APP_API_URL}users/${user.userId}`,
        method: "get",
        headers: apiHeaders(),
      })
        .then((result) => {
          const user = result.data.user;
          setTotalSitesByUser(user.sites);
          console.log(user.sites);
        })
        .catch((error) => {
          if (error.response.status) {
            console.error("Error getting the session user info." + error);
          }
        });
    };
    getAdminUser();
    getStatistics();
  }, []);
  console.log("statistics", statistics);
  return (
    <>
      <MainLayout>
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <DeviceCard totalDevices={statistics.totalDevices} />
                <UsersCard totalUsers={statistics.totalUsers} />
                {/* <LogisticCentreCard /> */}
                <TruckCard totalTrucks={statistics.totalTrucks}/>
                <GateCard />
              </div>
            </div>
            <div className="col-lg-12">
              <DashBoardColumnChart />
            </div>
            <div className="col-lg-12">
              <DashboardTable />
            </div>
            {/* <div className="col-lg-12">
              <DashBoardLineChart />
            </div> */}
          </div>
        </section>
      </MainLayout>
    </>
  );
};
