import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as Yup from 'yup';
import logo from '../../assets/images/Adani_2012_logo.png.webp';

type ForgetPasswordForm = {
    email: string;
    
}
const ForgetPassword: React.FC = () => {
    const forgetPasswordSchema = Yup.object().shape({
        email: Yup.string().email('Enter valid email.').required('Email is required.').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*$/, 'Email is invalid.')
    })

    const {
        register,
        handleSubmit,
        formState: {errors},

    } = useForm<ForgetPasswordForm>({
        resolver: yupResolver(forgetPasswordSchema)
    })

    const submitActionHandler =(data: ForgetPasswordForm) => {
        console.log(data);
    }
    return (
        <>
            <div className="container">
                <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                                <div className="d-flex justify-content-center py-4">
                                    <Link
                                        className="logo d-flex align-items-center w-auto" to={""}                                    >
                                         <img src={logo} style={{maxHeight:"120px"}} alt="logo" />
                                        {/* <span className="d-none d-lg-block">NiceAdmin</span> */}
                                    </Link>
                                </div>

                                <div className="card mb-3" onSubmit={handleSubmit(submitActionHandler)}>
                                    <div className="card-body">
                                        <div className="pt-4 pb-2">
                                            <h5 className="card-title text-center pb-0 fs-4">
                                                Trouble Logging in?
                                            </h5>
                                            <hr />
                                            <p className="text-center">
                                                Enter your email address and we'll send you a link to get back into your account
                                            </p>
                                        </div>

                                        <form className="row g-3" >
                                            <div className="col-12">
                                                {/* <label htmlFor="email" className="form-label">
                                                    Email
                                                </label> */}
                                                <input
                                                    {...register("email")}
                                                    type="email"
                                                    className={`form-control ${errors.email ? 'is-invalid' : '' } `}
                                                    id="yourPassword"
                                                    placeholder="Enter your email address"
                                                />
                                                <div className="invalid-feedback">
                                                    {errors.email?.message}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button className="btn btn-primary w-100" type="submit">
                                                    Reset Password
                                                </button>
                                            </div>
                                            <div className="col-12">
                                            <p className="mb-0">Have an account?
                                                <Link to={'/'}>Sign in</Link>
                                                </p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <footer className="footer">
                <div className="copyright">
                    © Copyright{" "}
                    <strong>
                        <span>Adani</span>
                    </strong>
                    . All Rights Reserved
                </div>
            </footer>
        </>
    );
};

export default ForgetPassword;
