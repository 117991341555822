import { Link } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  dateRangeValueState,
  toggleCalendarModalState,
  totalTripsCountState,
  tripsListState,
} from "../../atom/trip";
import { useEffect, useState } from "react";
import { Http } from "../../utility/http";
import { apiHeaders, formatDurationToTimeFormat } from "../../utility/generic";
import { Skelton } from "../shared/skelton";
import { Form } from "react-bootstrap";
import { userLoginSitesInfo } from "../../atom/generic";
// import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { format } from "date-fns";
import Button from "react-bootstrap/Button";
import { getUserDetails } from "../../utility/jwt";
import CalendarModal from "./calendarmodal/calendar-modal";
import * as XLSX from "xlsx";
import fileDownload from "js-file-download";

export const TripListTable = () => {
  const [tripList, setTripList] = useRecoilState(tripsListState);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { user } = getUserDetails();
  const [totalTrips, setTotalTrips] = useRecoilState(totalTripsCountState);
  const totalSites = useRecoilState(userLoginSitesInfo);
  const setTotalSitesByUser = useSetRecoilState(userLoginSitesInfo);
  const startIndex = (page - 1) * limit + 1;
  const [applyFilters, setApplyFilters] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const [filterOptions, setFilterOptions] = useState({
    timeRange: "",
    site: "",
    device: "",
    vehicle: "",
    container: "",
    status: "",
    isDateRange: false,
  });
  const setToggleCalendarModal = useSetRecoilState(toggleCalendarModalState);
  const [dateRangeValue, setDateRangeValue] =
    useRecoilState(dateRangeValueState);
  const options = totalSites[0].map((site: any) => ({
    value: site.id,
    label: site.siteName,
  }));

  useEffect(() => {
    const getAdminUser = async () => {
      await Http.request({
        url: `${process.env.REACT_APP_API_URL}users/${user.userId}`,
        method: "get",
        headers: apiHeaders(),
      })
        .then((result) => {
          const user = result.data.user;
          setTotalSitesByUser(user.sites);
          console.log(user.sites);
        })
        .catch((error) => {
          if (error.response.status) {
            console.error("Error getting the session user info." + error);
          }
        });
    };
    getAdminUser();
    getTrips();
  }, [applyFilters, page, limit]);

  // const [dateTime, setDateTime] = useState("");

  const getTrips = async () => {
    setLoading(true);
    await Http.request({
      url: `${process.env.REACT_APP_API_URL}trip?page=${page}&limit=${limit}&site=${filterOptions.site}&range=${filterOptions.timeRange}&device=${filterOptions.device}&vehicle=${filterOptions.vehicle}&container=${filterOptions.container}&status=${filterOptions.status}&startDate=${dateRangeValue.start}&endDate=${dateRangeValue.end}`,
      method: "get",
      headers: apiHeaders(),
    })
      .then((response) => {
        setTripList(response.data.trips);
        setTotalTrips(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setTripList([]);
        if (error.response) {
          console.error("Server Error:", error.response.status);
        } else if (error.request) {
          console.error("Network Error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };

  const getLastRouteData = (data: any) => {
    let lastElement = data.slice(-1);
    let { datetime } = lastElement[0];
    return format(datetime, "p");
  };
  const handleLimitChange = (newLimit: any) => {
    setLimit(newLimit);
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const filterFunction = () => {
    setApplyFilters(!applyFilters);
    if (filterOptions.isDateRange) {
      setPage(1);
      setResetKey((prevKey) => prevKey + 1);
      setToggleCalendarModal(false);
    }
  };

  const searchTimeRange = async (event: any) => {
    const { value } = event.target;
    if (value === "date_range") {
      setToggleCalendarModal(true);
      setFilterOptions((prevOptions) => ({
        ...prevOptions,
        isDateRange: true,
      }));
    } else {
      setFilterOptions((prevOptions) => ({
        ...prevOptions,
        timeRange: event.target.value,
      }));
    }
  };
  const searchSite = async (event: any) => {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      site: event.target.value,
    }));
  };

  console.log("site", filterOptions.site);

  const searchDevice = async (event: any) => {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      device: event.target.value,
    }));
  };
  const searchVehicle = async (event: any) => {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      vehicle: event.target.value,
    }));
  };
  const searchContainer = async (event: any) => {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      container: event.target.value,
    }));
  };
  const searchStatus = async (event: any) => {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      status: event.target.value,
    }));
  };
  console.log("filterOptions", filterOptions);
  const resetFilters = () => {
    setFilterOptions({
      timeRange: "",
      site: "",
      device: "",
      vehicle: "",
      container: "",
      status: "",
      isDateRange: false,
    });
    setApplyFilters(!applyFilters);
    setResetKey((prevKey) => prevKey + 1);
    setDateRangeValue((prevState) => ({
      ...prevState,
      start: "",
      end: "",
    }));
  };

  const [exportLoading, setExportLoading] = useState(false);
  const exportXlsx = () => {
    Http.request({
      url: `${process.env.REACT_APP_API_URL}trip?page=1&limit=100000&site=${filterOptions.site}&range=${filterOptions.timeRange}&device=${filterOptions.device}&vehicle=${filterOptions.vehicle}&container=${filterOptions.container}&status=${filterOptions.status}&startDate=${dateRangeValue.start}&endDate=${dateRangeValue.end}`,
      method: "get",
      headers: apiHeaders(),
    })
      .then((response) => {
        if (response.data.trips.length) {
          let tripObject: any = {};
          const tripData: any = [];
          for (const trip of response.data.trips) {
            tripObject = {};
            tripObject["Site"] = trip.site.siteName;
            tripObject["Date"] = format(trip.startDate, "PP");
            tripObject["Device"] =trip?.device?.deviceName;
            tripObject["Container No"] = trip.containerNo;
            tripObject["Vehicle No	"] = trip.truckNo;
            tripObject["Status"] = trip.type;
            tripObject["Total Distance"] = trip.totalDistance;
            tripObject["Start Time"] = format(trip.routeData[0].datetime, "p");
            tripObject["End Time"] = getLastRouteData(trip.routeData);
            tripObject["Duration"] =formatDurationToTimeFormat(trip?.totalDuration)
            tripData.push(tripObject);
          }

          const worksheet = XLSX.utils.json_to_sheet(tripData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
          const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
          });
          const dataBlob = new Blob([excelBuffer], {
            type: "application/octet-stream",
          });
          fileDownload(dataBlob, `trips.xlsx`);
        }
        setExportLoading(false);
      })
      .catch((error) => {
        setExportLoading(false);
      });
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h5
            className="card-title"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            Trip Analytics
            <Button onClick={exportXlsx}>Export</Button>
          </h5>
          <div className="datatable-wrapper datatable-loading card-footer sortable searchable fixed-columns">
            <div className="datatable-container">
              <div className="datatable-container">
                <h5>Search By</h5>
                <Row key={resetKey}>
                  <Row>
                    <Col>
                      <Form.Select
                        onChange={searchTimeRange}
                        aria-label="Default select example"
                      >
                        <option>Select Time Period</option>
                        <option value="last_week">Last Week</option>
                        <option value="last_month">Last Month</option>
                        <option value="last_quarter">Last Quarter</option>
                        <option value="last_year">Last Year</option>
                        <option value="date_range">Date Range</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Select
                        onChange={searchSite}
                        aria-label="Default select example"
                      >
                        <option value="">Site</option>
                        {options.length > 0 &&
                          options.map((site) => (
                            <option key={site.value} value={site.value}>
                              {site.label}
                            </option>
                          ))}
                      </Form.Select>
                    </Col>
                    {/* <Col>
                      <Form.Control
                        onChange={searchDevice}
                        placeholder="Device"
                      />
                    </Col> */}
                    <Col>
                      <Form.Control
                        onChange={searchVehicle}
                        placeholder="Vehicle"
                      />
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col>
                      <Form.Control
                        onChange={searchContainer}
                        placeholder="Container"
                      />
                    </Col>
                    <Col>
                      <Form.Select
                        aria-label="Default select example"
                        onClick={searchStatus}
                      >
                        <option value="">Status</option>
                        <option value="active">Active</option>
                        <option value="completed">Completed</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Button
                        onClick={filterFunction}
                        className="text-capitalize fw-medium"
                        style={{ width: "100%" }}
                        variant="primary"
                      >
                        <i className="bi bi-funnel"></i>
                        Filter
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={resetFilters}
                        className="text-capitalize fw-medium"
                        style={{ width: "100%" }}
                        variant="secondary"
                      >
                        <i className="bi bi-arrow-clockwise"></i>
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </Row>
              </div>
              <hr />
              <div className="table-responsive">
                <table className="table datatable datatable-table">
                  <thead>
                    <tr>
                      <th scope="col" data-sortable="true">
                        S.No.
                      </th>
                      <th scope="col" data-sortable="true">
                        Site
                      </th>
                      <th
                        scope="col"
                        data-sortable="true"
                        aria-sort="descending"
                        className="datatable-descending"
                      >
                        Date
                      </th>
                      <th scope="col" data-sortable="true">
                        Device
                      </th>
                      <th scope="col" data-sortable="true">
                        Container No
                      </th>
                      <th scope="col" data-sortable="true">
                        Vehicle No
                      </th>
                      <th scope="col" data-sortable="true">
                        Status
                      </th>
                      <th scope="col" data-sortable="true">
                        Total Distance
                      </th>
                      <th scope="col" data-sortable="true">
                        Start Time
                      </th>
                      <th scope="col" data-sortable="true">
                        End Time
                      </th>
                      <th scope="col" data-sortable="true">
                        Duration
                      </th>
                      <th scope="col" data-sortable="true">
                        Route
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading && <Skelton totalCol={12}></Skelton>}
                    {!loading && tripList.length === 0 && (
                      <tr>
                        <td colSpan={12}>Trips not found.</td>
                      </tr>
                    )}
                    {!loading &&
                      tripList.length > 0 &&
                      tripList.map((trip: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td>{startIndex + index}</td>
                            <td>{trip.site.siteName}</td>
                            <td>{format(trip.startDate, "PP")}</td>
                            <td>{trip?.device?.deviceName}</td>
                            <td>{trip.containerNo}</td>
                            <td>{trip.truckNo}</td>
                            <td>{trip.type}</td>
                            <td>{trip.totalDistance} KM</td>
                            {/* <td>{format(trip.startDate, "HH:mm:ss")}</td> */}
                            <td>{format(trip.routeData[0].datetime, "p")}</td>
                            <td>{getLastRouteData(trip.routeData)}</td>
                            <td>
                              {formatDurationToTimeFormat(trip?.totalDuration)}
                            </td>
                            <td>
                              <Link
                                to={`/trip/${trip.id}/route-details`}
                                className="link_color"
                              >
                                <i className="bi bi-geo-alt-fill"></i> View
                                Route
                              </Link>
                            </td>
                          </tr>
                        );
                      })}

                    {/* <tr data-index="0">
                      <td>1</td>
                      <td>12 Oct 2023</td>
                      <td>28Km</td>
                      <td> A </td>
                      <td>X,Y</td>
                      <td>
                        <Link
                          to={`/trip/${id}/route-details`}
                          className="link_color"
                        >
                          <i className="bi bi-geo-alt-fill"></i> View Route
                        </Link>
                      </td>
                    </tr>
                    <tr data-index="1">
                      <td>2</td>
                      <td>12 Oct 2023</td>
                      <td>28Km</td>
                      <td> A </td>
                      <td>X,Y</td>
                      <td>
                        <Link
                          to={`/trip/${id}/route-details`}
                          className="link_color"
                        >
                          <i className="bi bi-geo-alt-fill"></i> View Route
                        </Link>
                      </td>
                    </tr>
                    <tr data-index="2">
                      <td>3</td>
                      <td>12 Oct 2023</td>
                      <td>28Km</td>
                      <td> A </td>
                      <td>X,Y</td>
                      <td>
                        <Link
                          to={`/trip/${id}/route-details`}
                          className="link_color"
                        >
                          <i className="bi bi-geo-alt-fill"></i> View Route
                        </Link>
                      </td>
                    </tr>
                    <tr data-index="3">
                      <td>4</td>
                      <td>12 Oct 2024</td>
                      <td>28Km</td>
                      <td> A </td>
                      <td>X,Y</td>
                      <td>
                        <Link
                          to={`/trip/${id}/route-details`}
                          className="link_color"
                        >
                          <i className="bi bi-geo-alt-fill"></i> View Route
                        </Link>
                      </td>
                    </tr>
                    <tr data-index="4">
                      <td>5</td>
                      <td>12 Oct 2023</td>
                      <td>28Km</td>
                      <td> A </td>
                      <td>X,Y</td>
                      <td>
                        <Link
                          to={`/trip/${id}/route-details`}
                          className="link_color"
                        >
                          <i className="bi bi-geo-alt-fill"></i> View Route
                        </Link>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
              <div className="py-3">
                <div className="row flex-wrap">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-3 pagination-select-box">
                    <span className="pagination-span">Rows per page:</span>
                    <select
                      className="pagination-select"
                      onChange={(e) =>
                        handleLimitChange(Number(e.target.value))
                      }
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div>

                  <div className="col-lg-3 pagination-box">
                    <div className="pagination-num">
                      {(page - 1) * limit + 1} -{" "}
                      {Math.min(page * limit, totalTrips)}&nbsp; of &nbsp;
                      {totalTrips}
                    </div>
                    <button
                      className={`btn pagination-btn`}
                      onClick={() => handlePageChange(page - 1)}
                      disabled={page === 1}
                    >
                      <i className="bi bi-chevron-left"></i>
                    </button>
                    <button
                      className={`btn pagination-btn`}
                      onClick={() => handlePageChange(page + 1)}
                      disabled={page * limit >= totalTrips}
                    >
                      <i className="bi bi-chevron-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CalendarModal />
    </>
  );
};
