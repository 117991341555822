import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { Dashboard } from "./pages/dashboard";
import { Login } from "./pages/login";
import { NotFound } from "./pages/not-found";
import "./assets/css/custom.css";
import { getUserDetails } from "./utility/jwt";
import Protected from "./protected";
import UserProfile from "./pages/user-profile/user-profile";
import ForgetPassword from "./pages/forget-password/forget-password";
import { RecoilRoot } from "recoil";
import { DeviceRoutes } from "./routes/device-routes";
import { UsersRoutes } from "./routes/users-routes";
import { GateRoutes } from "./routes/gate-routes";
import { SiteRoutes } from "./routes/site-routes";
import { Setting } from "./pages/setting/account-setting";
import { TripList } from "./pages/trip/trip-list";
import { TripDetailPage } from "./pages/trip/trip-details";
import { AuditRoutes } from "./routes/audit-routes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const isLoggedIn = getUserDetails().isLoggedIn;
//const role = getUserDetails().user.role;
root.render(
  <RecoilRoot>
    <React.StrictMode>
      <Router basename="/">
        <Routes>
          {isLoggedIn ? (
            <Route path="/" element={<Navigate to="/dashboard" />} />
          ) : (
            <Route path="/" element={<Login />} />
          )}
          <Route
            path="/dashboard"
            element={
              <Protected>
                <Dashboard />
              </Protected>
            }
          />
          <Route path="forget-password" element={<ForgetPassword />} />
          <Route path="/gates/*" element={<GateRoutes />} />
          <Route path="/users/*" element={<UsersRoutes />} />
          <Route path="/devices/*" element={<DeviceRoutes />} />
          <Route path="sites/*" element={<SiteRoutes />} />
          <Route path="/audit/*" element={<AuditRoutes />} />
          <Route path="/trip" element={<TripList />}></Route>
          <Route path="/trip/:id/route-details" element={<TripDetailPage />} />
          <Route
            path="profile"
            element={
              <Protected>
                <UserProfile />
              </Protected>
            }
          />
          <Route
            path="setting"
            element={
              <Protected>
                <Setting />
              </Protected>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </React.StrictMode>
  </RecoilRoot>
);

reportWebVitals();
