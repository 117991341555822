import { Modal, Button } from "react-bootstrap";
import { useRecoilState } from "recoil";
import {
  siteLocationState,
  toggleSiteLocationModalState,
} from "../../atom/site";
import { useEffect, useRef } from "react";
import { loadModules, setDefaultOptions } from "esri-loader";

export const SiteLocation = () => {
  const [toggleSiteLocationModal, setToggleSiteLocationModal] = useRecoilState(
    toggleSiteLocationModalState
  );
  const [siteLocation, setsiteLocation] = useRecoilState(siteLocationState);
  const mapRef = useRef(null);

  useEffect(() => {
    // Load the required modules from the ArcGIS API

    setDefaultOptions({ css: true });
    loadModules([
      "esri/Map",
      "esri/views/MapView",
      "esri/Graphic",
      "esri/geometry/Point",
    ])
      .then(([Map, MapView, Graphic, Point]) => {
        // Create a map
        const map = new Map({
          basemap: "streets-navigation-vector",
        });

        // Create a view
        const view = new MapView({
          container: mapRef.current,
          map,
          center: [siteLocation.lon, siteLocation.lat], // Specify the initial center of the map
          zoom: 13, // Specify the initial zoom level
        });

        // Create a point at a specific location
        const point = new Point({
          type: "point",
          longitude: siteLocation.lon,
          latitude: siteLocation.lat,
        });

        // Create a graphic (pin) and add it to the map
        const graphic = new Graphic({
          geometry: point,
          symbol: {
            type: "simple-marker",
            color: [226, 119, 40], // Orange color
            outline: {
              color: [255, 255, 255], // White color
              width: 2,
            },
          },
        });

        view.graphics.add(graphic);
      })
      .catch((error) => {
        console.error("Error loading ArcGIS modules:", error);
      });
  }, [siteLocation.lat, siteLocation.lon]); // Run this effect only once, when the component mounts

  return (
    <>
      <Modal
        show={toggleSiteLocationModal}
        onHide={() => {
          setToggleSiteLocationModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Site Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div ref={mapRef} style={{ height: "300px" }}></div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setToggleSiteLocationModal(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
