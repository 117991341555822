import { useRecoilValue } from "recoil";
import { totalUsersCountState } from "../../../atom/users";
import { useEffect, useState } from "react";
import { Http } from "../../../utility/http";
import { apiHeaders } from "../../../utility/generic";

export const UsersCard = ({ totalUsers }: { totalUsers: number }) => {
  return (
    <div className="col-xxl-2 col-md-4">
      <div className="card info-card sales-card">
        <div className="card-body">
          <h5 className="card-title">Total Users</h5>
          <div className="d-flex align-items-center">
            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
              <i className="bi bi-people"></i>
            </div>
            <div className="ps-3">
              <h6>{totalUsers}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
