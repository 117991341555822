import { atom } from "recoil";

export const toggleUserDeleteModalState = atom({
  key: "toggleUserDeleteModalState",
  default: false,
});

export const userIdState = atom({
  key: "userIdState",
  default: "",
});

export const usersListState = atom({
  key: "usersListState",
  default: [],
});

export const totalUsersCountState = atom({
  key: "totalUsersCountState",
  default: 0,
});
