import React from "react";
import { getUserDetails } from "../../utility/jwt";

const ProfileOverview : React.FC = () => {
    const details = getUserDetails();
    const userInfo = details.user;
    return (
        <>
            <div
                className="tab-pane fade show active profile-overview"
                id="profile-overview"
                role="tabpanel"
            >
                

                <h5 className="card-title">Profile Details</h5>

                <div className="row">
                    <div className="col-lg-3 col-md-4 label ">Full Name</div>
                    <div className="col-lg-9 col-md-8">{userInfo.firstName} {userInfo.lastName}</div>
                </div>

                {/* <div className="row">
                                                <div className="col-lg-3 col-md-4 label">Company</div>
                                                <div className="col-lg-9 col-md-8">
                                                    Lueilwitz, Wisoky and Leuschke
                                                </div>
                                            </div> */}

                {/* <div className="row">
                    <div className="col-lg-3 col-md-4 label">Job</div>
                    <div className="col-lg-9 col-md-8">Web Designer</div>
                </div> */}

                {/* <div className="row">
                                                <div className="col-lg-3 col-md-4 label">Country</div>
                                                <div className="col-lg-9 col-md-8">USA</div>
                                            </div> */}

                {/* <div className="row">
                    <div className="col-lg-3 col-md-4 label">Address</div>
                    <div className="col-lg-9 col-md-8">
                        A108 Adam Street, New York, NY 535022
                    </div>
                </div> */}

                {/* <div className="row">
                    <div className="col-lg-3 col-md-4 label">Phone</div>
                    <div className="col-lg-9 col-md-8">
                        9876543210
                    </div>
                </div> */}

                <div className="row">
                    <div className="col-lg-3 col-md-4 label">Email</div>
                    <div className="col-lg-9 col-md-8">
                        {userInfo.email}
                    </div>
                </div>
            </div>

        </>
    )
}

export default ProfileOverview;