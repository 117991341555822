import Breadcrumb from "react-bootstrap/esm/Breadcrumb";
import { UserUpdateForm } from "../../components/user/user-update-form";
import { MainLayout } from "../../layout/main-layout";


export const UpdateUser = () => {
  

  return (
    <>
      <MainLayout>
      <div className="pagetitle">
      <h2 className="pagetitle" style={{ color: '#012970', fontWeight: '600', marginBottom: 0, fontSize: '24px', fontFamily: "Nunito, sans-serif" }} >Edit</h2>
        <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item href="/dashboard">Dashborad</Breadcrumb.Item>           
            <Breadcrumb.Item href="/users">Users</Breadcrumb.Item>
            <Breadcrumb.Item active>Edit</Breadcrumb.Item>
          </Breadcrumb>
      </div>
        <UserUpdateForm />
      </MainLayout>
    </>
  );
};
