import { useRecoilValue } from "recoil"
import { totalGatesState } from "../../../atom/gate"


export const GateCard = () => {
    const totalGates = useRecoilValue(totalGatesState)
    
    return (
        <div className="col-xxl-2 col-md-4">
            <div className="card info-card sales-card">
                <div className="card-body">
                    <h5 className="card-title">Total Gate</h5>
                    <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-door-open"></i>
                        </div>
                        <div className="ps-3">
                            <h6>{totalGates}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}