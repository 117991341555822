import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { useRecoilState } from "recoil";
import { toggleToasterState } from "../../atom/generic";

export const Toaster = () => {
  const [toggleToaster, setToggleToaster] = useRecoilState(toggleToasterState);
  const { isShow, data } = toggleToaster;

  return (
    <>
      <ToastContainer
        className="position-fixed top-0 end-0 p-3"
        position="top-end"
      >
        <Toast
          onClose={() =>
            setToggleToaster({
              isShow: false,
              data: { title: "", message: "" },
            })
          }
          show={isShow}
          delay={7000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">{data.title}</strong>
          </Toast.Header>
          <Toast.Body>{data.message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};
