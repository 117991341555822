export const TruckCard = ({ totalTrucks }: { totalTrucks: number }) => {
  return (
    <div className="col-xxl-2 col-md-4">
      <div className="card info-card sales-card">
        <div className="card-body">
          <h5 className="card-title">Total Truck Entered</h5>
          <div className="d-flex align-items-center">
            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
              <i className="bi bi-truck"></i>
            </div>
            <div className="ps-3">
              <h6>{totalTrucks}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
