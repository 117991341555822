import { atom } from "recoil";

export const siteIdState = atom({
  key: "siteIdState",
  default: "",
});

export const sitesListState = atom({
  key: "sitesListState",
  default: [],
});

export const totalSitesCountState = atom({
  key: "totalSitesCountState",
  default: 0,
});

export const toggleSiteDeleteModalState = atom({
  key: "toggleSiteDeleteModalState",
  default: false,
});

export const toggleSiteLocationModalState = atom({
  key: "toggleSiteLocationModalState",
  default: false,
});

export const siteLocationState = atom({
  key: "siteLocationState",
  default: {
    lat: "",
    lon: "",
  },
});
