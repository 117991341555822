import { Breadcrumb } from "react-bootstrap";
import { MainLayout } from "../../../layout/main-layout";
import { DeviceLogTable } from "../../../components/audit/device-log-table";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { activeMenuState } from "../../../atom/generic";

export const DevicesLogsList = () => {
  const setActiveMenu = useSetRecoilState(activeMenuState);
  useEffect(() => {
    setActiveMenu("audit");
  }, []);
  return (
    <MainLayout>
      <div className="">
        <h2
          className="pagetitle"
          style={{
            color: "#012970",
            fontWeight: "600",
            marginBottom: 0,
            fontSize: "24px",
            fontFamily: "Nunito, sans-serif",
          }}
        >
          Devices
        </h2>
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item active>Audit</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <DeviceLogTable />
          </div>
        </div>
      </section>
    </MainLayout>
  );
};
