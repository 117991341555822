import { Route, Routes } from "react-router-dom"
import Protected from "../protected"
import { Gate } from "../pages/gate/gate-list"
import { EditGate } from "../pages/gate/edit-gate"
import { AddGate } from "../pages/gate/add-gate"

export const GateRoutes = () => {
    return (
        <Protected>
            <Routes>
                <Route>
                    <Route index element={<Gate />} />
                    <Route path="edit/:id" element={<EditGate />} />
                    <Route path="add" element={<AddGate />} />
                </Route>
            </Routes>
        </Protected>
    )
}