import { atom } from "recoil";

export const deviceIdState = atom({
    key: 'getIdState',
    default: '',
});


export const toggleDeviceDeleteModalState = atom({
    key: 'toggleDeviceDeleteModalState',
    default: false,
});

export const deviceListState = atom({
    key: 'deviceListState',
    default: []
});

export const totalDeviceCountState = atom({
    key: 'totalDeviceCountState',
    default: 0,
})