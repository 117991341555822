import Cookie from "js-cookie";
import jwt_decode from "jwt-decode";
import { JwtUser } from "../interface/jwt.interface";

export const getUserDetails = () => {
  const token: string | undefined = Cookie.get("token");
  try {
    if (token) {
      const { firstName, lastName, role, userId, email , siteId }: JwtUser =
        jwt_decode(token);

      return {
        isLoggedIn: true,
        user: {
          userId,
          firstName,
          lastName,
          role,
          email,
          siteId
        },
      };
    } else {
      return {
        isLoggedIn: false,
        user: {
          role: "",
        },
      };
    }
  } catch (e) {
    return {
      isLoggedIn: false,
      user: {
        role: "",
      },
    };
  }
};

