import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import SuccessMessage from "../shared/success-message";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { getUserDetails } from "../../utility/jwt";
import { AlterError } from "../shared/alter-error";
import { Spinner } from "react-bootstrap";
import { useSetRecoilState } from "recoil";
import { toggleToasterState } from "../../atom/generic";
import Cookie from "js-cookie";

type EditProFileForm = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
};

const EditProfile: React.FC = () => {
  const details = getUserDetails();
  const navigate = useNavigate();
  const userId = details.user.userId;
  const setToggleToaster = useSetRecoilState(toggleToasterState);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const editProfileSchema = Yup.object().shape({
    firstName: Yup.string().required("Fullname is required."),
    lastName: Yup.string().required("Fullname is required."),
    phoneNumber: Yup.string()
      .required("Phone no. is required.")
      .matches(/^(0|91)?[6-9][0-9]{9}$/, "Phone no. is invalid."),
    email: Yup.string()
      .email("Email is invalid")
      .required("Email is required.")
      .matches(/^\S+@\S+\.\S+$/, "Email is invalid."),
  });

  useEffect(() => {
    getOneUser();
  }, []);

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EditProFileForm>({
    resolver: yupResolver(editProfileSchema),
  });

  const getOneUser = async () => {
    await Http.request({
      url: `${process.env.REACT_APP_API_URL}users/${userId}`,
      method: "get",
      headers: apiHeaders(),
    })
      .then((result) => {
        const user = result.data.user;
        setValue("firstName", user.firstName);
        setValue("lastName", user.lastName);
        setValue("phoneNumber", user.phoneNumber);
        setValue("email", user.email);
      })
      .catch((error) => {
        if (error.response) {
          console.error("Server Error:", error.response.status);
        } else if (error.request) {
          console.error("Network Error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };

  const editFormActionHandler = (data: EditProFileForm) => {
    setIsLoading(true);
    Http.request({
      url: `${process.env.REACT_APP_API_URL}users/${userId}/my-profile`,
      method: "patch",
      data: data,
      headers: apiHeaders(),
    })
      .then((response) => {
        setToggleToaster({
          isShow: true,
          data: {
            title: "User",
            message: "Profile updated successfully",
          },
        });
        Cookie.set('token', response.data.accessToken)
        setTimeout(() => {
          navigate(-1);
          setIsLoading(false);
        }, 1500);
      })
      .catch((error) => {
        if (error) {
          console.log(error.response.data.message);
          setIsLoading(false);
          setError(error.response.data.message);
          setTimeout(() => {
            setError("");
          }, 9000);
        }
      });
  };
  return (
    <>
      {error && <AlterError message={error}></AlterError>}
      <form onSubmit={handleSubmit(editFormActionHandler)}>
        <div className="row mb-3">
          <label
            htmlFor="fullName"
            className="col-md-4 col-lg-3 col-form-label"
          >
            First Name
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              {...register("firstName")}
              type="text"
              className={`form-control ${errors.firstName ? "is-invalid" : ""}`}
              id="fullName"
            />
            <div className="invalid-feedback">{errors.firstName?.message}</div>
          </div>
        </div>

        <div className="row mb-3">
          <label
            htmlFor="fullName"
            className="col-md-4 col-lg-3 col-form-label"
          >
            Last Name
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              {...register("lastName")}
              type="text"
              className={`form-control ${errors.lastName ? "is-invalid" : ""}`}
              id="fullName"
            />
            <div className="invalid-feedback">{errors.lastName?.message}</div>
          </div>
        </div>

        {/* <div className="row mb-3">
                <label
                    htmlFor="Job"
                    className="col-md-4 col-lg-3 col-form-label"
                >
                    Job
                </label>
                <div className="col-md-8 col-lg-9">
                    <input
                        name="job"
                        type="text"
                        className="form-control"
                        id="Job"
                        value="Web Designer"
                    />
                </div>
            </div> */}

        <div className="row mb-3">
          <label htmlFor="Phone" className="col-md-4 col-lg-3 col-form-label">
            Phone
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              {...register("phoneNumber")}
              type="text"
              className={`form-control ${
                errors.phoneNumber ? "is-invalid" : ""
              }`}
              id="Phone"
            />
            <div className="invalid-feedback">
              {errors.phoneNumber?.message}
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <label htmlFor="Email" className="col-md-4 col-lg-3 col-form-label">
            Email
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              {...register("email")}
              type="email"
              readOnly
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
              id="Email"
            />
            <div className="invalid-feedback">{errors.email?.message}</div>
          </div>
        </div>

        {/* <div className="text-center">
          <button type="submit" className="btn btn-primary">
            Save Changes
          </button>
        </div> */}
        <div className="text-center">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner
                className="me-1"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Save Changes"
            )}
          </button>
        </div>
        <div className="message py-3">
          {message && <SuccessMessage message={message} />}
        </div>
      </form>
    </>
  );
};

export default EditProfile;
