import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  gateIdState,
  gateListState,
  toggleGateDeleteModalState,
} from "../../atom/gate";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { AlterError } from "../shared/alter-error";

type DeleteSuccessProps = {
  onDeleteSuccess: Function;
}

export const DeleteGate = ({onDeleteSuccess}:DeleteSuccessProps ) => {
  const [deleteError, setDeleteError] = useState("");
  const [toggleGateDeleteModal, setToggleGateDeleteModal] = useRecoilState(
    toggleGateDeleteModalState
  );
  const gateId = useRecoilValue(gateIdState);
  const setGateList = useSetRecoilState(gateListState);

  const deleteGate = () => {
    Http.request({
      url: `${process.env.REACT_APP_API_URL}gate/${gateId}`,
      method: "delete",
      headers: apiHeaders(),
    })
      .then((result) => {
        setGateList((prevState: any) => {
          const gates: any = [...prevState];
          const index = gates.findIndex((gate: any) => gate.id === gateId);
          if (index !== -1) {
            gates.splice(index, 1);
          }
          return gates;
        });
        setToggleGateDeleteModal(false);
        onDeleteSuccess();
      })
      .catch((error) => {
        console.log(error.response.data.message)
        if(error){
          setDeleteError(error.response.data.message)
           setTimeout(() => {
            setDeleteError("");
              }, 9000);
        }
      });
  };

  return (
    <>
    
      <Modal
        show={toggleGateDeleteModal}
        onHide={() => {
          setToggleGateDeleteModal(false);
        }}
      >
        {deleteError && (
       <AlterError message={deleteError}></AlterError>
     )}
        <Modal.Header closeButton>
          <Modal.Title>Delete Gate</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete this gate?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setToggleGateDeleteModal(false);
            }}
          >
            No
          </Button>
          <Button variant="primary" onClick={deleteGate}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
