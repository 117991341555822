import Breadcrumb from "react-bootstrap/esm/Breadcrumb";
import { MainLayout } from "../../layout/main-layout";
import { TripListTable } from "../../components/trip/trip-list-table";
import { useSetRecoilState } from "recoil";
import { activeMenuState } from "../../atom/generic";

export const TripList = () => {
  const setActiveMenu = useSetRecoilState(activeMenuState);
  setActiveMenu("trip");
  return (
    <>
      <MainLayout>
        <div className="">
          <h2
            className="pagetitle"
            style={{
              color: "#012970",
              fontWeight: "600",
              marginBottom: 0,
              fontSize: "24px",
              fontFamily: "Nunito, sans-serif",
            }}
          >
            Trips
          </h2>
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Trips</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <section className="section contact">
          <div className="row">
            <div className="col-lg-12"></div>
            <TripListTable />
          </div>
        </section>
      </MainLayout>
    </>
  );
};
