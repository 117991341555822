
import { useSetRecoilState } from "recoil";
import { EditGateForm } from "../../components/gate/edit-gate-form";
import { MainLayout } from "../../layout/main-layout";
import { activeMenuState } from "../../atom/generic";
import Breadcrumb from "react-bootstrap/esm/Breadcrumb";



export const EditGate = () => {
  const setActiveMenu= useSetRecoilState(activeMenuState);
  setActiveMenu('gate');
  return (
    <>
      <MainLayout>
      <div className="pagetitle">
      <h2 className="pagetitle" style={{ color: '#012970', fontWeight: '600', marginBottom: 0, fontSize: '24px', fontFamily: "Nunito, sans-serif" }} >Edit Gate</h2>
        <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item href="/dashboard">Dashborad</Breadcrumb.Item>           
            <Breadcrumb.Item href="/gates">Gates</Breadcrumb.Item>
            <Breadcrumb.Item active>Edit</Breadcrumb.Item>
          </Breadcrumb>
      </div>
        <EditGateForm></EditGateForm>
      </MainLayout>
    </>
  );
};
