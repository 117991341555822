import { Breadcrumb } from "react-bootstrap"
import { MainLayout } from "../../layout/main-layout"
import { UpdateDeviceFormSection } from "../../components/device/update-device-form"
import { useSetRecoilState } from "recoil";
import { activeMenuState } from "../../atom/generic";


export const UpdateDevice = () => {
    const setActiveMenu= useSetRecoilState(activeMenuState);
    setActiveMenu('device');
    return (
        <>
            <MainLayout>
                <div className="">
                    <h2
                        className="pagetitle"
                        style={{
                            color: "#012970",
                            fontWeight: "600",
                            marginBottom: 0,
                            fontSize: "24px",
                            fontFamily: "Nunito, sans-serif",
                        }}
                    >
                        Edit
                    </h2>
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item href="/devices">Devices</Breadcrumb.Item>
                        <Breadcrumb.Item active>Edit</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <section className="section" >
                        <div className="row">
                            <div className="col-lg-12">
                                <UpdateDeviceFormSection />
                            </div>
                        </div>
                </section>
            </MainLayout>
        </>
    )
}