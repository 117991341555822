import { atom } from "recoil";

export const tripIdState = atom({
    key: "tripIdState",
    default: "",
  });

export const tripsListState = atom({
  key: "tripsListState",
  default: [],
});


export const totalTripsCountState = atom({
    key: "totalTripsCountState",
    default: 0,
  });

  export const toggleCalendarModalState = atom({
    key: 'toggleCalendarModalState',
    default: false
  })

  export const dateRangeValueState = atom({
    key: 'dateRangeValueState',
    default: {
      start: "",
      end: ""
    }
  })
