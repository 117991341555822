import ChangePassword from "./change-password";
import EditProfile from "./edit-profile";
import ProfileImageCard from "./profile-image-card";
import ProfileOverview from "./profile-overview";

export const ProfileSection = () => {
    return (
        <>
            {/* User Profile Main Content */}
            <section className="section profile">
                <div className="row">
                    {/* User Image Card */}
                   
                    {/* End User Image Card */}
                    <div className="col-xl-8">
                        <div className="card">
                            <div className="card-body pt-3">
                                {/* <!-- Bordered Tabs --> */}
                                <ul className="nav nav-tabs nav-tabs-bordered" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link active"
                                            data-bs-toggle="tab"
                                            data-bs-target="#profile-overview"
                                            aria-selected="true"
                                            role="tab"
                                        >
                                            Overview
                                        </button>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link"
                                            data-bs-toggle="tab"
                                            data-bs-target="#profile-edit"
                                            aria-selected="false"
                                            role="tab"
                                        >
                                            Edit Profile
                                        </button>
                                    </li>
                                    {/* 
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className="nav-link"
                                                data-bs-toggle="tab"
                                                data-bs-target="#profile-settings"
                                                aria-selected="false"
                                                role="tab"
                                            >
                                                Settings
                                            </button>
                                        </li> */}

                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link"
                                            data-bs-toggle="tab"
                                            data-bs-target="#profile-change-password"
                                            aria-selected="false"
                                            role="tab"
                                        >
                                            Change Password
                                        </button>
                                    </li>
                                </ul>
                                <div className="tab-content pt-2">
                                    {/* Profile Info */}
                                    <ProfileOverview />
                                    {/* End Profile Info */}

                                    <div
                                        className="tab-pane fade profile-edit pt-3"
                                        id="profile-edit"
                                        role="tabpanel"
                                    >
                                        {/* <!-- Profile Edit Form --> */}
                                        <EditProfile />
                                        {/* <!-- End Profile Edit Form --> */}
                                    </div>
                                    {/* Change Password div */}
                                    <div
                                        className="tab-pane fade pt-3"
                                        id="profile-change-password"
                                        role="tabpanel"
                                    >
                                        {/* <!-- Change Password Form --> */}
                                        <ChangePassword />
                                        {/* <!-- End Change Password Form --> */}
                                    </div>
                                    {/* End Change Password div */}
                                </div>
                                {/* <!-- End Bordered Tabs --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*End User Profile Main Content */}
        </>
    );
};
