import { Link } from "react-router-dom";
import { useState, useEffect, SetStateAction } from "react";
import { apiHeaders } from "../../utility/generic";
import { Http } from "../../utility/http";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  deviceIdState,
  deviceListState,
  toggleDeviceDeleteModalState,
  totalDeviceCountState,
} from "../../atom/device";
import { DeleteDeviceModal } from "./delete-device-modal"; 
import { Skelton } from "../shared/skelton";

export const DeviceTable = () => {
  const [deviceList, setDeviceList] = useRecoilState(deviceListState);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const [totalDevices, setTotalDevices] = useRecoilState(totalDeviceCountState);
  const setToggleDeviceDeleteModal = useSetRecoilState(
    toggleDeviceDeleteModalState
  );
  const setDeviceId = useSetRecoilState(deviceIdState);
  const startIndex = (page - 1) * limit + 1;

  useEffect(() => {
    getDevicesItems();
  }, [page, limit, searchTerm]);

  const getDevicesItems = async () => {
    setLoading(true);
    await Http.request({
      url: `${process.env.REACT_APP_API_URL}device?page=${page}&limit=${limit}&search=${searchTerm}`,
      method: "get",
      headers: apiHeaders(),
    })
      .then((response) => {
        setDeviceList(response.data.device);
        setTotalDevices(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setDeviceList([]);
        if (error.response) {
          console.error("Server Error:", error.response.status);
        } else if (error.request) {
          console.error("Network Error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };

  const handleDeleteSuccess = () => {
    // Adjust the page if needed
    if (page !== 1 && page * limit >= totalDevices && deviceList.length === 1) {
      setPage(page - 1);
    }
    // Fetch the updated data
    getDevicesItems();
  };

  const searchDeviceByName = async (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleLimitChange = (newLimit: any) => {
    setLimit(newLimit);
    setPage(1);
  };

  const handlePageChange = (newPage: SetStateAction<number>) => {
    setPage(newPage);
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Device List</h5>
          <div className="datatable-wrapper datatable-loading card-footer sortable searchable">
            <div className="datatable-top">
              <div className="datatable-dropdown"></div>
              <div className="datatable-search ">
                {/* <Link to={"/devices/add"}>
                  <button className="btn btn-primary mx-2">
                    <i className="bi bi-plus-lg"></i> Add Device
                  </button>
                </Link> */}
                <input
                  className="datatable-input search-box"
                  placeholder="Search..."
                  type="search"
                  title="Search within table"
                  onChange={searchDeviceByName}
                />
              </div>
            </div>
            <div className="datatable-container">
              <table className="table datatable datatable-table table-hover table-responsive">
                <thead>
                  <tr>
                    <th scope="col" data-sortable="true">
                      S.No
                    </th>
                    <th scope="col" data-sortable="true">
                      Action
                    </th>

                    <th scope="col" data-sortable="true">
                      Asset Id
                    </th>
                    <th scope="col" data-sortable="true">
                      Device Id
                    </th>
                    <th scope="col" data-sortable="true">
                      Details
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading && <Skelton  totalCol={5} />}
                  {!loading && deviceList.length === 0 && (
                    <tr>
                      <td colSpan={6}>Devices not found.</td>
                    </tr>
                  )}
                  {!loading &&
                    deviceList.length > 0 &&
                    deviceList.map((device: any, index: number) => {
                      return (
                        <tr key={device.id}>
                          <td>{startIndex + index}</td>
                          <td>
                            <Link to={`/devices/edit/${device.id}`}>
                              <button className="btn btn-primary me-1">
                                <i className="bi bi-pencil-square"></i>
                              </button>
                            </Link>
                            <Link to={``}>
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => {
                                  setDeviceId(device.id);
                                  setToggleDeviceDeleteModal(true);
                                }}
                              >
                                <i className="bi bi-trash"></i>
                              </button>
                            </Link>
                          </td>

                          <td>{device.deviceName}</td>
                          <td>{device.imeiNumber}</td>
                          <td>
                            {device.description === null ||
                            device.description.length < 0 ? (
                              <div>
                                <h5>-</h5>
                              </div>
                            ) : (
                              device.description
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>

              {deviceList.length > 0 && (
                <div className="row">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-3 pagination-select-box">
                    <div className={`${deviceList.length < 5 ? "heddin" : ""}`}>
                      <span className="pagination-span">Rows per page:</span>
                      <select
                        className="pagination-select"
                        onChange={(e) =>
                          handleLimitChange(Number(e.target.value))
                        }
                      >
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-3 pagination-box">
                    <div className="pagination-num">
                      {(page - 1) * limit + 1} -{" "}
                      {Math.min(page * limit, totalDevices)}&nbsp; of &nbsp;
                      {totalDevices}
                    </div>
                    <button
                      className={`btn pagination-btn`}
                      onClick={() => handlePageChange(page - 1)}
                      disabled={page === 1}
                    >
                      <i className="bi bi-chevron-left"></i>
                    </button>
                    <button
                      className={`btn pagination-btn`}
                      onClick={() => handlePageChange(page + 1)}
                      disabled={page * limit >= totalDevices}
                    >
                      <i className="bi bi-chevron-right"></i>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <DeleteDeviceModal onDeleteSuccess={handleDeleteSuccess} />
    </>
  );
};
