import { loadModules, setDefaultOptions } from "esri-loader";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { apiHeaders } from "../../utility/generic";
import { Http } from "../../utility/http";
import { Container, Row } from "react-bootstrap";
import { Skelton } from "../shared/skelton";

interface Point {
  datetime: any;
  lat: number;
  lon: number;
}
export const TripDetails = () => {
  const [loading, setLoading] = useState(false);
  const [trip, setTrip] = useState<any>({});
  const [totalDistance, setTotalDistance] = useState();
  const [lineData, setLineData] = useState<Point[]>([]);
  const { id } = useParams();

  const mapRef = useRef(null);

  useEffect(() => {
    getTrip();
  }, []);
  console.log(totalDistance);
  const getTrip = async () => {
    setLoading(true);
    await Http.request({
      url: `${process.env.REACT_APP_API_URL}trip/${id}`,
      method: "get",
      headers: apiHeaders(),
    })
      .then((response) => {
        setTrip(response.data.trip);
        console.log("response.data.trip", response.data.trip);
        setTotalDistance(response.data.totalDistance);
        setLineData(response.data.trip.routeData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setLineData([]);
        if (error.response) {
          console.error("Server Error:", error.response.status);
        } else if (error.request) {
          console.error("Network Error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };

  // ... (import statements remain the same)

  useEffect(() => {
    setDefaultOptions({ css: true });

    if (lineData.length === 0) {
      return;
    }

    let view: any;
    let featureLayer: any;

    loadModules([
      "esri/Map",
      "esri/views/MapView",
      "esri/Graphic",
      "esri/geometry/Point",
      "esri/geometry/Polyline",
      "esri/PopupTemplate",
      "esri/widgets/Popup",
      "esri/layers/FeatureLayer",
      "esri/geometry/geometryEngine",
    ])
      .then(
        ([
          Map,
          MapView,
          Graphic,
          Point,
          Polyline,
          PopupTemplate,
          Popup,
          FeatureLayer,
          geometryEngine,
        ]) => {
          const map = new Map({
            basemap: "streets-navigation-vector",
          });

          featureLayer = new FeatureLayer();

          view = new MapView({
            container: mapRef.current,
            map,
            center: [lineData[0].lon, lineData[0].lat],
            zoom: 14,
          });

          // Add point graphics
          lineData.forEach((pointData, index: number) => {
            const pointGeometry = new Point({
              type: "point",
              longitude: pointData.lon,
              latitude: pointData.lat,
            });

            const pointGraphic = new Graphic({
              geometry: pointGeometry,
              symbol: {
                type: "simple-marker",
                color:
                  index === 0
                    ? [0, 255, 0]
                    : index === lineData.length - 1
                    ? [255, 0, 0]
                    : [66,133,244],
                outline: {
                  color: [255, 255, 255],
                  width: 1,
                },
                size: index === 0 || index === lineData.length - 1 ? 11 : 10,
              },
              attributes: {
                DateTime: pointData.datetime,
              },
              popupTemplate: new PopupTemplate({
                title: "Time",
                content: "Time: {DateTime}", // Display datetime information
              }),
            });

            view.graphics.add(pointGraphic);
          });

          // Add line graphic
          const lineGeometry = new Polyline({
            paths: lineData.map((point) => [point.lon, point.lat]),
          });

          const lineGraphic = new Graphic({
            geometry: lineGeometry,
            symbol: {
              type: "simple-line",
              color: [66,133,244],
              width: 1,
            },
          });

          view.graphics.add(lineGraphic);

          const polylineExtent = geometryEngine.geodesicBuffer(
            lineGraphic.geometry,
            100,
            "meters"
          );

          view.goTo(polylineExtent);
          const popup = new Popup({
            view,
            container: document.createElement("div"),
          });

          view.ui.add(popup, "top-right");
          view.on("pointer-move", (event: any) => {
            view.hitTest(event).then((response: any) => {
              const graphic = response.results[0]?.graphic;
              if (graphic) {
                popup.location = event.mapPoint;
                popup.features = [graphic];
                popup.visible = true;
              } else {
                popup.visible = false;
              }
            });
          });

          view.on("pointer-out", () => {
            popup.visible = false;
          });
        }
      )
      .catch((error) => {
        console.error("Error loading ArcGIS modules:", error);
      });
  }, [lineData]);

  const getCoordsFromRouteData = (data: Point[] = lineData, find: string) => {
    if (data.length > 0) {
      if (find === "first") {
        const firstEL = data.slice(0, 1);
        const { lat, lon } = firstEL[0];
        return `${Number(lat).toFixed(6)}, ${Number(lon).toFixed(6)}`;
      } else if (find === "last") {
        const lastEL = data.slice(-1);
        const { lat, lon } = lastEL[0];
        return `${Number(lat).toFixed(6)}, ${Number(lon).toFixed(6)}`;
      }
    }
    return "";
  };

  return (
    <>
      <Row>
        {/* <div className="col-xxl-2 col-md-4">
          <div className="card info-card sales-card">
            <div className="card-body">
              <h5 className="card-title">Entry Gate</h5>
              <div className="d-flex align-items-center">
                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                  <i className="bi bi-door-open"></i>
                </div>
                <div className="ps-3">
                  <h6>A</h6>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="col-xxl-4 col-md-4">
          <div className="card info-card sales-card">
            <div className="card-body">
              <h5 className="card-title">Total Distance</h5>
              <div className="d-flex align-items-center">
                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                  <i className="bi bi-signpost-2-fill"></i>
                </div>
                <div className="ps-3 pt-2">
                  <h6>{totalDistance} KM</h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xxl-8 col-md-8">
          <div className="card info-card sales-card">
            <div className="card-body">
              <h5 className="card-title">Destination</h5>
              <div className="d-flex align-items-center">
                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                  <i className="bi bi-signpost-2-fill"></i>
                </div>
                <div className="ps-3 pt-2">
                  <h6>
                    <b>{getCoordsFromRouteData(undefined, "first")}</b> To{" "}
                    <b>{getCoordsFromRouteData(undefined, "last")}</b>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>
      <Row>
        <div className="card">
          <h5 className="card-title">Today</h5>
          <div className="container-fluid">
            <div ref={mapRef} style={{ height: "60vh" }}></div>
          </div>
        </div>
      </Row>
    </>
  );
};
