import { atom } from "recoil";

export const usersLogsListState = atom({
  key: "usersLogsListState",
  default: [],
});

export const totalUsersLogsCountState = atom({
  key: "totalUsersLogsCountState",
  default: 0,
});

export const sitesLogsListState = atom({
  key: "sitesLogsListState",
  default: [],
});

export const totalSitesLogsCountState = atom({
    key: "totalSitesLogsCountState",
    default: 0,
  });

export const devicesLogsListState = atom({
  key: "devicesLogsListState",
  default: [],
});

export const totalDevicesLogsCountState = atom({
    key: "totalDevicesLogsCountState",
    default: 0,
  });
