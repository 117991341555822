import { useEffect, useState } from "react";
import ReactApexCharts from "react-apexcharts";
import { apiHeaders } from "../../utility/generic";
import { Http } from "../../utility/http";
import { Skelton } from "../shared/skelton";

export const DashBoardColumnChart = () => {
  const [loading, setLoading] = useState(false);

  const [graphData, setGraphData] = useState([
    {
      date: "",
      totatTruckEntry: 0,
      totalDistance: 0,
      totalTime: 0,
    },
  ]);
  const fetchGraphData = () => {
    setLoading(true);
    Http.request({
      url: `${process.env.REACT_APP_API_URL}analytics/graph-data`,
      method: "get",
      headers: apiHeaders(),
    })
      .then((response) => {
        setGraphData(response.data.usage);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error.message);
        setLoading(false);
        setGraphData([]);
        if (error.response) {
          console.error("Server Error:", error.response.status);
        } else if (error.request) {
          console.error("Network Error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };
  useEffect(() => {
    fetchGraphData();
  }, []);

  console.log("graphData", graphData);

  const series = [
    {
      name: "Truck Entries (numbers)",
      data: graphData.map((data) => data.totatTruckEntry),
    },
    {
      name: "Cumulative Device Usage (hours)",
      data: graphData.map((data) => data.totalTime),
    },
    {
      name: "Total Distance Travelled (km)",
      data: graphData.map((data) => data.totalDistance),
    },
  ];

  const categories = graphData.map((data) => data.date);

  return (
    <>
      {/* {loading && <Skelton></Skelton>} */}
      {!loading && graphData.length > 0 && (
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Truck Entry</h5>
            <ReactApexCharts
              series={series}
              options={{
                chart: { type: "bar", height: 380 },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    columnWidth: "55%",
                  },
                },
                dataLabels: { enabled: false },
                stroke: { show: true, width: 2, colors: ["transparent"] },
                xaxis: {
                  categories,
                },
                yaxis: {
                  title: {
                    text: "n (numbers), h (hours), k (kilometer)",
                  },
                  labels: {
                    formatter: function(value) {
                      return value.toFixed(2);
                    }
                  }
                },
                fill: { opacity: 1 },
                tooltip: {
                  y: {
                    formatter: function (val: any) {
                      return val.toFixed(2);
                    },
                  },
                },
              }}
              type="bar"
              height={380}
            />
          </div>
        </div>
      )}
    </>
  );
};
