import { useEffect, useState } from "react";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { Skelton } from "../shared/skelton";

type TripStatusProps = {
  status: string;
};

export const DashboardTable = () => {
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState<number>(5);
  const [page, setPage] = useState(1);
  const [totalRecentTrips, setTotalRecentTrips] = useState<number>(0);
  const [recentTrips, setRecentTrips] = useState([
    {
      id: " ",
      truckNo: "",
      containerNo: "",
      type: "i",
      startDate: "",
    },
  ]);
  const [searchTerm, setSearchTerm] = useState("");
  const startIndex = (page - 1) * limit + 1;

  const TripStatusBagdeClass = ({ status }: TripStatusProps) => {
    let badgeClass: string = "";
    switch (status) {
      case "Delivered":
        badgeClass = "bg-success";
        break;

      case "Entered":
        badgeClass = "bg-info";
        break;

      case "Delivering":
        badgeClass = "bg-primary";
        break;

      default:
        badgeClass = "";
        break;
    }
    return <span className={`badge rounded-pill ${badgeClass}`}>{status}</span>;
  };
  const transformStatus = (status: any) => {
    const statusTransformations: any = {
      in: "Entered",
      internal: "Delivering",
      out: "Delivered",
    };
    return statusTransformations[status] || status;
  };
  const handleLimitChange = (newLimit: any) => {
    setLimit(newLimit);
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getRecentTrips = async () => {
    setLoading(true);
    await Http.request({
      url: `${process.env.REACT_APP_API_URL}analytics/recent-trips?page=${page}&limit=${limit}&search=${searchTerm}`,
      method: "get",
      headers: apiHeaders(),
    })
      .then((response) => {
        setRecentTrips(response.data.recentTrips);
        setTotalRecentTrips(response.data.totalTrips);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setRecentTrips([
          { id: " ", truckNo: "", type: "i", startDate: "", containerNo: "" },
        ]);
        if (error.response) {
          console.error("Server Error:", error.response.status);
        } else if (error.request) {
          console.error("Network Error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };
  useEffect(() => {
    getRecentTrips();
  }, [page, limit ,searchTerm]);

  const searchByHandler = async (event: any) => {
    setSearchTerm(event.target.value);
  };
  return (
    <>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Recent Truck Entry</h5>

          <div className="datatable-wrapper datatable-loading card-footer sortable searchable">
            <div className="datatable-top">
              <div className="datatable-dropdown">
                <label>
                  <select
                    className="pagination-select"
                    onChange={(e) => handleLimitChange(Number(e.target.value))}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    {/* <option value="20">20</option>
                    <option value="25">25</option> */}
                  </select>{" "}
                  Entries per page
                </label>
              </div>
              <div className="datatable-search">
                <input
                  className="datatable-input search-box"
                  placeholder="Search..."
                  type="search"
                  title="Search within table"
                  onChange={searchByHandler}
                />
              </div>
            </div>
            <div className="datatable-container">
              <table className="table datatable datatable-table table-hover">
                <thead>
                  <tr>
                    <th
                      data-sortable="true"
                      style={{ width: 7.383966244725738 }}
                    >
                      No
                    </th>
                    <th
                      data-sortable="true"
                      style={{ width: 26.371308016877638 }}
                    >
                      Truck No.
                    </th>
                    <th
                      data-sortable="true"
                      style={{ width: 35.54852320675106 }}
                    >
                      Container No.
                    </th>
                    <th
                      data-sortable="true"
                      style={{ width: 10.759493670886076 }}
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading && <Skelton></Skelton>}
                  {!loading && recentTrips.length === 0 && (
                    <tr>
                      <td colSpan={6}>Recent Trips not found.</td>
                    </tr>
                  )}
                  {!loading &&
                    recentTrips.length > 0 &&
                    recentTrips.map((trip: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{startIndex + index}</td>
                          <td>{trip.truckNo}</td>
                          <td>{trip.containerNo}</td>
                          <td className="text-capitalize">
                            <TripStatusBagdeClass
                              status={transformStatus(trip.type)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  {/* <tr data-index="0">
                    <td>1</td>
                    <td>KA19P8488</td>
                    <td>04</td>
                    <td>
                      <span className="badge bg-success">Delivered</span>
                    </td>
                  </tr>
                  <tr data-index="1">
                    <td>2</td>
                    <td>KA19P8400</td>
                    <td>01</td>
                    <td>
                      <span className="badge bg-success">Delivered</span>
                    </td>
                  </tr>
                  <tr data-index="2">
                    <td>3</td>
                    <td>KA19P8490</td>
                    <td>01</td>
                    <td>
                      <span className="badge bg-success">Delivered</span>
                    </td>
                  </tr>
                  <tr data-index="3">
                    <td>4</td>
                    <td>KA19P7490</td>
                    <td>03</td>
                    <td>
                      <span className="badge bg-success">Delivered</span>
                    </td>
                  </tr>
                  <tr data-index="4">
                    <td>5</td>
                    <td>KA19P7498</td>
                    <td>02</td>
                    <td>
                      <span className="badge bg-success">Delivered</span>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
            <div className="datatable-bottom">
              <div className="datatable-info">
                Showing {(page - 1) * limit + 1} to{" "}
                {Math.min(page * limit, totalRecentTrips)} entries of{" "}
                {totalRecentTrips}{" "}
              </div>
              <nav className="datatable-pagination">
                <button
                  className={`btn pagination-btn`}
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 1}
                >
                  <i className="bi bi-chevron-left"></i>
                </button>
                <button
                  className={`btn pagination-btn`}
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page * limit >= totalRecentTrips}
                >
                  <i className="bi bi-chevron-right"></i>
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
