import { Route, Routes } from "react-router-dom";
import Protected from "../protected";
import { Sites } from "../pages/sites/site-list";
import { AddSitePage } from "../pages/sites/add-sites";
import { EditSitePage } from "../pages/sites/edit-site";
import { activeMenuState } from "../atom/generic";
import { useSetRecoilState } from "recoil";
import { useEffect } from "react";
import { NotFound } from "../pages/not-found";

export const SiteRoutes = () => {
  const setActiveMenu = useSetRecoilState(activeMenuState);
  useEffect(() => {
    setActiveMenu("site");
  }, []);
  return (
    <>
      <Protected>
        <Routes>
          <Route index element={<Sites />} />
          <Route path="edit/:id" element={<EditSitePage />} />
          <Route path="add" element={<AddSitePage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Protected>
    </>
  );
};
