import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { toggleToasterState } from "../../atom/generic";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
// import { AlterError } from "../shared/alter-error";
import { Spinner } from "react-bootstrap";
import { AlterError } from "../shared/alter-error";

type AddSiteForm = {
  siteCode: string;
  siteName: string;
  address: string;
  siteLat: number;
  siteLong: number;
  networkLayer: string;
  siteLocation: string;
};

export const AddSiteFormSection = () => {
  const navigate = useNavigate();
  const setToggleToaster = useSetRecoilState(toggleToasterState);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmittedSuccessful, setIsSubmittedSuccessful] = useState(false);

  const addSiteValidationSchema = Yup.object().shape({
    siteCode: Yup.string().required("Site code is required."),
    siteName: Yup.string().required("Site name is required."),
    address: Yup.string().required("Site address is required."),
    siteLat: Yup.number()
      .typeError("latitude must be a number.")
      .required("Latitude is required."),
    siteLong: Yup.number()
      .typeError("Longitude must be a number.")
      .required("Longitude is required."),
    networkLayer: Yup.mixed()
      // .matches(
      //   /((https|http):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      //   "Enter valid url."
      // )
      .required("Network layer file is required."),
    siteLocation: Yup.mixed().required("SiteLocation file  is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddSiteForm>({
    // @ts-expect-error
    resolver: yupResolver(addSiteValidationSchema),
  });

  const handleNumericInput = (event: any) => {
    event.target.value = event.target.value.replace(/[^0-9.-]/g, "");
  };

  const [formData, setFormData] = useState({
    siteLocation: null,
    networkLayer: null,
  });

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setFormData({
      ...formData,
      siteLocation: file,
    });
  };

  const handleNetworkLayerFileChange = (event: any) => {
    const file = event.target.files[0];
    setFormData({
      ...formData,
      networkLayer: file,
    });
  };

  const onSubmitHandler = (data: AddSiteForm) => {
    const form = new FormData();
    // @ts-expect-error
    form.append("document", formData.siteLocation);
    form.append("address", data.address);
    // @ts-expect-error
    form.append("networkLayer", formData.networkLayer);
    form.append("siteCode", data.siteCode);
    // @ts-expect-error
    form.append("siteLat", data.siteLat);
    // @ts-expect-error
    form.append("siteLong", data.siteLong);
    form.append("siteName", data.siteName);
    console.log(form);

    //return;
    setLoading(true);
    setIsSubmittedSuccessful(true);
    Http.request({
      url: `${process.env.REACT_APP_API_URL}site`,
      method: "post",
      data: form,
      headers: apiHeaders(),
    })
      .then((result) => {
        setToggleToaster({
          isShow: true,
          data: {
            title: "Site",
            message: result.message,
          },
        });
        setTimeout(() => {
          navigate(-1);
          setLoading(false);
        }, 1500);
      })
      .catch((error) => {
        setLoading(false);
        setIsSubmittedSuccessful(false);
        console.log(error);
        // if (error.response.status >= 400 || error.response.status <= 500) {
        //   setErrorMessage(error.response.data.message);
        // }
        if (error.response?.status) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(
            "Network error. Please check your internet connection."
          );
        }
      });
  };

  const downloadCSV = () => {
    // Sample data
    const data = [
      ["Lat", "Long", "Place"],
      ["23.1544510062778", "72.6663959622079", "PDPU"],
      [
        "23.1538022541003",
        "72.6608247111726",
        "Gujarat National Law University",
      ],
    ];

    // Convert data to CSV format
    const csvContent = data.map((row) => row.join(",")).join("\n");

    // Create a Blob object
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Create a URL for the Blob object
    const url = window.URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement("a");

    // Set the href attribute of the link to the URL
    link.href = url;

    // Set the download attribute of the link
    link.setAttribute("download", "sample-location.csv");

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically trigger the click event of the link
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            {errorMessage && <AlterError message={"*" + errorMessage} />}
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Add Site</h5>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Site Code* :
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.siteCode ? "is-invalid" : ""
                        }`}
                        id="inputText"
                        {...register("siteCode")}
                        placeholder="Enter site code"
                      />
                      <div className="invalid-feedback">
                        {errors.siteCode?.message}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Name* :
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.siteName ? "is-invalid" : ""
                        }`}
                        id="inputText"
                        {...register("siteName")}
                        placeholder="Enter site name"
                      />
                      <div className="invalid-feedback">
                        {errors.siteName?.message}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Address* :
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.address ? "is-invalid" : ""
                        }`}
                        id="inputEmail"
                        {...register("address")}
                        placeholder="Enter site address"
                      />
                      <div className="invalid-feedback">
                        {errors.address?.message}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Latitude* :
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.siteLat ? "is-invalid" : ""
                        }`}
                        id="inputEmail"
                        {...register("siteLat")}
                        onInput={handleNumericInput}
                        placeholder="Enter site latitude"
                      />
                      <div className="invalid-feedback">
                        {errors.siteLat?.message}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Longitude* :
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.siteLong ? "is-invalid" : ""
                        }`}
                        id="inputEmail"
                        {...register("siteLong")}
                        onInput={handleNumericInput}
                        placeholder="Enter site longitude"
                      />
                      <div className="invalid-feedback">
                        {errors.siteLong?.message}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Upload Network Layer*:
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="file"
                        accept=".mmpk"
                        className={`form-control ${
                          errors.networkLayer ? "is-invalid" : ""
                        }`}
                        id="networkLayerFile"
                        {...register("networkLayer")}
                        placeholder="Upload Network Layer file."
                        onChange={handleNetworkLayerFileChange}
                      />
                      <div className="invalid-feedback">
                        {errors.networkLayer?.message}
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Upload Site Location* :
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="file"
                        accept=".csv"
                        id="locationFileRequest"
                        {...register("siteLocation")}
                        className={`form-control ${
                          errors.siteLocation ? "is-invalid" : ""
                        }`}
                        placeholder="Upload Site Location"
                        onChange={handleFileChange}
                      />
                      <p style={{ cursor: "pointer", color: "#012970" }}>
                        {/* <Link
                          target="_blank"
                          to={"/assets/other/sample-location.csv"}
                        >
                          Download sample format
                        </Link> */}
                        <span
                          style={{ color: "#4154f1", cursor: "pointer" }}
                          onClick={downloadCSV}
                        >
                          Download sample format
                        </span>
                      </p>
                      <div className="invalid-feedback">
                        {errors?.siteLocation?.message}
                      </div>
                    </div>
                  </div>

                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary mx-2"
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner
                          className="me-1"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                    {!isSubmittedSuccessful && (
                      <button
                        type="reset"
                        className="btn btn-secondary"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        Cancel
                      </button>
                    )}
                    {/* {errorMessage && !isSubmitSuccessful && (
                      <button
                        type="reset"
                        className="btn btn-secondary"
                        onClick={() => {
                          reset();
                        }}
                      >
                        Reset
                      </button>
                    )}
                    {errorMessage && (
                      <div className="container my-1 w-50 h-25 shadow-lg">
                        <AlterError message={errorMessage} />
                      </div>
                    )} */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
