import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Http } from "../../utility/http";
import { useNavigate } from "react-router-dom";
import { apiHeaders } from "../../utility/generic";
import { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { toggleToasterState } from "../../atom/generic";
import { Spinner } from "react-bootstrap";
// import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { AlterError } from "../shared/alter-error";

interface IGateForm {
  name: string;
  description: string;
  latitude: number;
  longitude: number;
}
export const GateForm = () => {
  const [error, setError] = useState("");
  const [toggleToaster, setToggleToaster] = useRecoilState(toggleToasterState);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Gate name is required."),
    description: Yup.string().required("Description is required."),
    latitude: Yup.number().required("Latitude is required."),
    longitude: Yup.number().required("Longitude is required."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful }, 
    reset,
  } = useForm<IGateForm>({
    resolver: yupResolver(validationSchema),
  });

  const navigate = useNavigate();
  const onSubmitHandler = (data: IGateForm) => {
    setIsLoading(true);
    Http.request({
      url: `${process.env.REACT_APP_API_URL}gate`,
      method: "post",
      data: data,
      headers: apiHeaders(),
    })
      .then((result) => {
        setIsLoading(false);
        setToggleToaster({
          isShow: true,
          data: {
            title: "Gate",
            message: result.message,
          },
        });
        navigate("/gates");
      })
      .catch((error) => {
        setIsLoading(false);
        if (error) {
          setError(error.response.data.message);
          setTimeout(() => {
            setError("");
          }, 9000);
        }
      });
  };

  return (
    <>
      {/* {error && <AlterError message={error}></AlterError>} */}
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            {errorMessage && <AlterError message={"*" + errorMessage} />}
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Add Gate</h5>

                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Gate Name
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="inputText"
                        {...register("name")}
                        placeholder="Enter Gate Name"
                      />
                      <p className="v-error">{errors.name?.message}</p>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Description
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="inputEmail"
                        {...register("description")}
                        placeholder="Enter Gate Description"
                      />
                      <p className="v-error">{errors.description?.message}</p>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Latitude
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="inputEmail"
                        {...register("latitude")}
                        placeholder="Enter Gate Latitude"
                      />
                      <p className="v-error">{errors.latitude?.message}</p>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Longitude
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="inputEmail"
                        {...register("longitude")}
                        placeholder="Enter Gate Longitude"
                      />
                      <p className="v-error">{errors.longitude?.message}</p>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary mx-2"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Spinner
                          className="me-1"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                    {!isSubmitSuccessful && !isLoading && (
                      <button
                        type="reset"
                        className="btn btn-secondary"
                        onClick={() => {
                          navigate(-1)
                        }}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
