import { useEffect, useState } from "react";
import { totalUsersLogsCountState, usersLogsListState } from "../../atom/audit";
import { useRecoilState } from "recoil";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { Skelton } from "../shared/skelton";
import { format } from "date-fns";

type UserStatusProps = {
  status: string;
};

export const UsersLogTable = () => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const startIndex = (page - 1) * limit + 1;

  //recoil state
  const [userLogsList, setUserLogsList] = useRecoilState(usersLogsListState);
  const [totalUsersLogs, setTotalUsersLogs] = useRecoilState(
    totalUsersLogsCountState
  );

  useEffect(() => {
    const getUsersLogs = async () => {
      setLoading(true);
      await Http.request({
        url: `${process.env.REACT_APP_API_URL}audit/users-logs?page=${page}&limit=${limit}&user=${searchTerm}`,
        method: "get",
        headers: apiHeaders(),
      })
        .then((response) => {
          setUserLogsList(response.data.usersLogs);
          setTotalUsersLogs(response.data.total);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setUserLogsList([]);
          if (error.response) {
            console.error("Server Error:", error.response.status);
          } else if (error.request) {
            console.error("Network Error:", error.request);
          } else {
            console.error("Error:", error.message);
          }
        });
    };

    getUsersLogs();
  }, [page, limit, searchTerm]);

  const UserStatusBagdeClass = ({ status }: UserStatusProps) => {
    let badgeClass: string = "";
    switch (status) {
      case "Active":
        badgeClass = "bg-success";
        break;

      case "Revoked":
        badgeClass = "bg-secondary";
        break;

      case "Rejected":
        badgeClass = "bg-danger";
        break;

      case "Pending":
        badgeClass = "bg-warning";
        break;

      default:
        badgeClass = "";
        break;
    }
    return <span className={`badge rounded-pill ${badgeClass}`}>{status}</span>;
  };

  const transformRole = (role: any) => {
    const roleTransformations: any = {
      gate_keeper: "Gate Keeper",
      admin: "Admin",
      super_admin: "Super Admin",
      business_user: "Business User",
      adid_ldap: "Adid Ldap",
    };

    return roleTransformations[role] || role;
  };
  const transformStatus = (status: any) => {
    const statusTransformations: any = {
      pending: "Pending",
      hold: "Revoked",
      approved: "Active",
      reject: "Rejected",
    };
    return statusTransformations[status] || status;
  };

  const searchUserByName = async (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleLimitChange = (newLimit: any) => {
    setLimit(newLimit);
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Users Log list</h5>
        <div className="datatable-wrapper datatable-loading card-footer sortable searchable fixed-columns">
          <div className="datatable-top">
            <div className="datatable-dropdown"></div>
            <div className="datatable-search">
              <input
                className="datatable-input search-box"
                placeholder="Search..."
                type="search"
                title="Search within table"
                onChange={searchUserByName}
              />
            </div>
          </div>
          <div className="datatable-container table-responsive">
            <table className="table datatable datatable-table table-hover">
              <thead>
                <tr>
                  <th scope="col">S.No.</th>
                  <th scope="col">Current Name</th>
                  <th scope="col">Previous Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone no.</th>
                  <th scope="col">Role</th>
                  <th scope="col">Site</th>
                  <th scope="col">Status</th>
                  <th scope="col">Updated By</th>
                  <th scope="col">Updated On</th>
                </tr>
              </thead>
              <tbody>
                {loading && <Skelton totalCol={10}></Skelton>}
                {!loading && userLogsList.length === 0 && (
                  <tr>
                    <td colSpan={12}>Users Logs not found.</td>
                  </tr>
                )}
                {!loading &&
                  userLogsList.length > 0 &&
                  userLogsList.map((user: any, index: number) => {
                    return (
                      <tr key={index + 1}>
                        <td>{startIndex + index}</td>
                        <td>{user.currentUpdatedUser.firstName + " " + user.currentUpdatedUser.lastName}</td>
                        <td>{user.firstName + " " + user.lastName}</td>
                        <td>{user.email}</td>
                        <td>{user.phoneNumber}</td>
                        <td>{transformRole(user.role)}</td>
                        <td>
                          <ul>
                            {user.sites.length > 0 &&
                              user.sites.map((site: any) => {
                                return <li key={site.id} style={{listStyle: 'none'}} >{site.siteName}</li>;
                              })}
                          </ul>
                        </td>
                        <td className="text-capitalize">
                          <UserStatusBagdeClass
                            status={transformStatus(user.verificationStatus)}
                          />
                        </td>
                        <td>{user.updatedUserBy.firstName + " " + user.updatedUserBy.lastName}</td>
                        <td>{format(user.updatedDateTime, "PP,p")}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {totalUsersLogs > limit && (
              <div className="row">
                <div className="col-lg-6"></div>
                <div className="col-lg-3 pagination-select-box">
                  <span className="pagination-span">Rows per page:</span>
                  <select
                    className="pagination-select"
                    onChange={(e) => handleLimitChange(Number(e.target.value))}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                  </select>
                </div>
                <div className="col-lg-3 pagination-box">
                  <div className="pagination-num">
                    {(page - 1) * limit + 1} -{" "}
                    {Math.min(page * limit, totalUsersLogs)} of {totalUsersLogs}
                  </div>
                  <button
                    className={`btn pagination-btn`}
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 1}
                  >
                    <i className="bi bi-chevron-left"></i>
                  </button>
                  <button
                    className={`btn pagination-btn`}
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page * limit >= totalUsersLogs}
                  >
                    <i className="bi bi-chevron-right"></i>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
