import { Route, Routes } from "react-router-dom";
import Protected from "../protected";
import { UsersLogsList } from "../pages/audit/users/users-logs-list";
import { DevicesLogsList } from "../pages/audit/devices/devices-logs-list";
import { SitesLogsList } from "../pages/audit/sites/sites-logs-list";
import { NotFound } from "../pages/not-found";

export const AuditRoutes = () => {
  
  return (
    <Protected>
      <Routes>
        <Route>
          <Route path="sites" index element={<SitesLogsList />} />
          <Route path="users" element={<UsersLogsList />} />
          <Route path="devices" element={<DevicesLogsList />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Protected>
  );
};
