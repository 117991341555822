import { Route, Routes } from "react-router";
import Protected from "../protected";
import { Devices } from "../pages/device/device-list";
// import { AddDevice } from "../pages/device/add-device";
import { UpdateDevice } from "../pages/device/update-device";
import { NotFound } from "../pages/not-found";

export const DeviceRoutes = () => {
  return (
    <>
      <Protected>
        <Routes>
          <Route index element={<Devices />} />
          <Route path="edit/:id" element={<UpdateDevice />} />
          {/* <Route path="add" element={<AddDevice />} /> */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Protected>
    </>
  );
};
