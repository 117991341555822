import { Breadcrumb } from "react-bootstrap";
import { Toaster } from "../../components/shared/toaster";
import { MainLayout } from "../../layout/main-layout";
import { SiteTable } from "../../components/sites/site-table-list";

export const Sites = () => {
  return (
    <>
      <MainLayout>
        <div className="">
          <h2
            className="pagetitle"
            style={{
              color: "#012970",
              fontWeight: "600",
              marginBottom: 0,
              fontSize: "24px",
              fontFamily: "Nunito, sans-serif",
            }}
          >
            Sites
          </h2>
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Sites</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <SiteTable />
            </div>
          </div>
        </section>
        <Toaster />
      </MainLayout>
    </>
  );
};
