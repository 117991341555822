import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Http } from "../../utility/http";
import { useNavigate } from "react-router-dom";
import { apiHeaders } from "../../utility/generic";
import { useEffect, useState } from "react";
import { AlterError } from "../shared/alter-error";
import { useSetRecoilState } from "recoil";
import { toggleToasterState } from "../../atom/generic";
import { Spinner } from "react-bootstrap";
import SuccessMessage from "../shared/success-message";

interface ISettingForm {
  deviceSecurityToken: string;
}
export const SettingForm = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const setToggleToaster = useSetRecoilState(toggleToasterState);
  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = Yup.object({
    deviceSecurityToken: Yup.string()
      .matches(/^\d{4}$/, "Please enter a valid four-digit number")
      .required("Device Token is required"),
  });
  // const validationSchema = Yup.object().shape({
  //   deviceSecurityToken: Yup.string().required("Device Token is required."),
  // });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<ISettingForm>({
    resolver: yupResolver(validationSchema),
  });

  //   const [gateDetails, setGateDetails] = useState({
  //     name: "",
  //     latitude: "",
  //     longitude: "",
  //   });

  useEffect(() => {
    getSettingDetails();
  }, []);

  const getSettingDetails = () => {
    Http.request({
      url: `${process.env.REACT_APP_API_URL}setting`,
      method: "get",
      headers: apiHeaders(),
    })
      .then((result) => {
        //setGateDetails(result);
        setValue("deviceSecurityToken", result.data.deviceSecurityToken);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const onSubmitHandler = (data: ISettingForm) => {
    setIsLoading(true);
    Http.request({
      url: `${process.env.REACT_APP_API_URL}setting`,
      method: "put",
      data: data,
      headers: apiHeaders(),
    })
      .then((response) => {
        setToggleToaster({
          isShow: true,
          data: {
            title: "Device",
            message: "Device token updated successfully",
          },
        });
        setTimeout(() => {
          navigate(-1);
          setIsLoading(false);
        }, 1500);
      })
      .catch((error) => {
        if (error) {
          console.log(error.response.data.message);
          setIsLoading(false);
          setError(error.response.data.message);
          setTimeout(() => {
            setError("");
          }, 9000);
        }
      });
    //console.log(data)
  };

  return (
    <>
      {error && <AlterError message={error}></AlterError>}
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Setting</h5>

                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Device Token
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="inputText"
                        {...register("deviceSecurityToken")}
                      />
                      <p className="v-error">
                        {errors.deviceSecurityToken?.message}
                      </p>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Spinner
                          className="me-1"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                    {/* <button type="submit" className="btn btn-primary mx-2">
                      Update
                    </button> */}
                  </div>
                  <div className="message py-3">
                    {message && <SuccessMessage message={message} />}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
