import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import SuccessMessage from "../shared/success-message";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { AlterError } from "../shared/alter-error";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { toggleToasterState } from "../../atom/generic";

type ChangePasswordForm = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

const ChangePassword: React.FC = () => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const setToggleToaster = useSetRecoilState(toggleToasterState);
  const [oldPassword, setOldPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState(false);
  const changePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Current password is required."),
    newPassword: Yup.string()
      .required("New password is required.")
      .min(8, "Password must be at least 8 characters.")
      .max(20, "Password must not exceed 20 characters.")
      .matches(
        /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
        "Password should contain atleast one special character, one uppercase letter, one lowercase letter, and one number."
      ),
    confirmNewPassword: Yup.string()
      .required("Password is required.")
      .oneOf([Yup.ref("newPassword")], "New Password do not match."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ChangePasswordForm>({
    resolver: yupResolver(changePasswordSchema),
  });

  const submitActionHandler = (data: ChangePasswordForm) => {
    Http.request({
      url: `${process.env.REACT_APP_API_URL}auth/change-password`,
      method: "post",
      data: data,
      headers: apiHeaders(),
    })
      .then((response) => {
        setToggleToaster({
          isShow: true,
          data: {
            title: "User",
            message: "Password updated successfully",
          },
        });
        setTimeout(() => {
          navigate(-1);
          setIsLoading(false);
        }, 1500);
      })

      //  reset();

      .catch((error) => {
        if (error) {
          console.log(error.response.data.message);
          setError(error.response.data.message);
          setTimeout(() => {
            setError("");
          }, 9000);
        }
      });
    // console.log(data);
    // setMessage('Password Updated.');
    // reset();
    // setTimeout(() => {
    // setMessage('');
    // }, 2500)
  };

  return (
    <>
      {error && <AlterError message={error}></AlterError>}
      <form onSubmit={handleSubmit(submitActionHandler)}>
        <div className="row mb-3">
          <label
            htmlFor="oldPassword"
            className="col-md-4 col-lg-3 col-form-label"
          >
            Current Password
          </label>
          <div className="col-md-8 col-lg-9">
            <div className="col input-group">
              <input
                {...register("oldPassword")}
                type={oldPassword ? "text" : "password"}
                className={`form-control ${
                  errors.oldPassword ? "is-invalid" : ""
                }`}
                id="oldPassword"
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span
                    className={`${
                      oldPassword ? "bi bi-eye-slash-fill" : "bi bi-eye-fill"
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => setOldPassword((prevState) => !prevState)}
                  ></span>
                </div>
              </div>
              <div className="invalid-feedback">
                {errors.oldPassword?.message}
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <label
            htmlFor="newPassword"
            className="col-md-4 col-lg-3 col-form-label"
          >
            New Password
          </label>
          <div className="col-md-8 col-lg-9">
            <div className="col input-group">
              <input
                {...register("newPassword")}
                type={newPassword ? "text" : "password"}
                className={`form-control ${
                  errors.newPassword ? "is-invalid" : ""
                }`}
                id="newPassword"
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span
                    className={`${
                      newPassword ? "bi bi-eye-slash-fill" : "bi bi-eye-fill"
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => setNewPassword((prevState) => !prevState)}
                  ></span>
                </div>
              </div>
              <div className="invalid-feedback">
                {errors.newPassword?.message}
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <label
            htmlFor="confirmNewPassword"
            className="col-md-4 col-lg-3 col-form-label"
          >
            Re-enter New Password
          </label>
          <div className="col-md-8 col-lg-9">
            <div className="col input-group">
              <input
                {...register("confirmNewPassword")}
                type={confirmNewPassword ? "text" : "password"}
                className={`form-control ${
                  errors.confirmNewPassword ? "is-invalid" : ""
                }`}
                id="confirmNewPassword"
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span
                    className={`${
                      confirmNewPassword
                        ? "bi bi-eye-slash-fill"
                        : "bi bi-eye-fill"
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setConfirmNewPassword((prevState) => !prevState)
                    }
                  ></span>
                </div>
              </div>
              <div className="invalid-feedback">
                {errors.confirmNewPassword?.message}
              </div>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button type="submit" className="btn btn-primary">
            Change Password
          </button>
        </div>
      </form>
      <div className="message py-3">
        {message && <SuccessMessage message={message} />}
      </div>
    </>
  );
};

export default ChangePassword;
