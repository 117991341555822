import { Breadcrumb } from "react-bootstrap";
import { MainLayout } from "../../layout/main-layout";
import { AddSiteFormSection } from "../../components/sites/add-site-form";

export const AddSitePage = () => {
  return <>
  <MainLayout>
    <div className="pagetitle">
      <h1>Add</h1>
    </div>
    <Breadcrumb className="breadcrumb">
      <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
      <Breadcrumb.Item href="/sites">Sites</Breadcrumb.Item>
      <Breadcrumb.Item active>Add</Breadcrumb.Item>
    </Breadcrumb>
      <AddSiteFormSection />
  </MainLayout>
  </>;
};
