import { Modal, Button } from "react-bootstrap";
import { useRecoilState } from "recoil";

import { useEffect, useRef } from "react";
import { loadModules, setDefaultOptions } from "esri-loader";
import { gateLocationState, toggleGateLocationModalState } from "../../atom/gate";

export const GateLocation = () => {
  const [toggleGateLocationModal, setToggleGateLocationModal] = useRecoilState(
    toggleGateLocationModalState
  );
  const [gateLocation, setGateLocation] = useRecoilState(gateLocationState);
  const mapRef = useRef(null);

  useEffect(() => {
    setDefaultOptions({css: true})
    // Load the required modules from the ArcGIS API
    loadModules([
      "esri/Map",
      "esri/views/MapView",
      "esri/Graphic",
      "esri/geometry/Point",
    ])
      .then(([Map, MapView, Graphic, Point]) => {
        // Create a map
        const map = new Map({
          basemap: "streets-navigation-vector",
        });

        // Create a view
        const view = new MapView({
          container: mapRef.current,
          map,
          center: [gateLocation.lon, gateLocation.lat], // Specify the initial center of the map
          zoom: 13, // Specify the initial zoom level
        });

        // Create a point at a specific location
        const point = new Point({
          type: "point",
          longitude: gateLocation.lon,
          latitude: gateLocation.lat,
        });

        // Create a graphic (pin) and add it to the map
        const graphic = new Graphic({
          geometry: point,
          symbol: {
            type: "simple-marker",
            color: [226, 119, 40], // Orange color
            outline: {
              color: [255, 255, 255], // White color
              width: 2,
            },
          },
        });

        view.graphics.add(graphic);
      })
      .catch((error) => {
        console.error("Error loading ArcGIS modules:", error);
      });
  }, [gateLocation.lat, gateLocation.lon]); // Run this effect only once, when the component mounts

  return (
    <>
      <Modal
        show={toggleGateLocationModal}
        onHide={() => {
          setToggleGateLocationModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Gate Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div ref={mapRef} style={{ height: "300px" }}></div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setToggleGateLocationModal(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
