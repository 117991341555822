import axios from "axios";
import Cookies from "js-cookie";
export class Http {
  static async request({ url, method, data, headers }: any) {
    try {
      const result = await axios({
        method,
        url,
        data,
        headers,
      });

      return result.data;
    } catch (error: any) {
      if (error.response.status === 401) {
        
        if (url.indexOf("login") === -1 && url.indexOf("register") === -1) {
          Cookies.remove("token");
          window.location.href = "/";
        }
        
      }
      throw error;
    }
  }
}
