import { Link, useLocation } from "react-router-dom";
import { activeMenuState } from "../atom/generic";
import { useRecoilValue } from "recoil";

export const SiderBar = () => {
  const activeMenu = useRecoilValue(activeMenuState);
  const {pathname} = useLocation();
  console.log('activeMenu' , activeMenu)
  return (
    <>
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link
              className={
                "nav-link " + (activeMenu !== "dashboard" ? "collapsed" : "")
              }
              to="/dashboard"
            >
              <i className="bi bi-grid"></i>
              <span>Dashboard</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link
              className={
                "nav-link " + (activeMenu !== "site" ? "collapsed" : "")
              }
              to="/sites"
            >
              <i className="bi bi-map"></i>
              <span>Site Management</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link
              className={
                "nav-link " + (activeMenu !== "user" ? "collapsed" : "")
              }
              to="/users"
            >
              <i className="bi bi-person"></i>
              <span>User Management</span>
            </Link>
          </li>

          {/* <li className="nav-item">
            <Link
              className={
                "nav-link " + (activeMenu !== "gate" ? "collapsed" : "")
              }
              to="/gates"
            >
              <i className="bi bi-door-open"></i>
              <span>Entry Gates</span>
            </Link>
          </li> */}

          <li className="nav-item">
            <Link
              className={
                "nav-link " + (activeMenu !== "device" ? "collapsed" : "")
              }
              to="/devices"
            >
              <i className="bi bi-phone"></i>
              <span>Devices</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link
              className={
                "nav-link " + (activeMenu !== "trip" ? "collapsed" : "")
              }
              to="/trip"
            >
              <i className="bi bi-graph-up"></i>
              <span>Trip Analytics</span>
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link
              className={
                "nav-link " + (activeMenu !== "audit" ? "collapsed" : "")
              }
              data-bs-target="#tables-nav"
              data-bs-toggle="collapse"
              aria-expanded="false"
              to={"/audit/sites"}
            >
              <i className="bi bi-layout-text-window-reverse"></i>
              <span>Audit</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </Link>
            <ul
              id="tables-nav"
              className={"nav-content collapse" + (activeMenu === "audit" ? "show" : "")}
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/audit/sites" className={pathname.includes('audit/sites') ? 'active': ''}>
                  <i className="bi bi-circle"></i>
                  <span>Site&#39;s Log</span>
                </Link>
              </li>
              <li>
                <Link to="/audit/users" className={pathname.includes('audit/users') ? 'active': ''} >
                  <i className="bi bi-circle"></i>
                  <span>User&#39;s Log</span>
                </Link>
              </li>
              <li>
                <Link to="/audit/devices" className={pathname.includes('audit/devices') ? 'active': ''} >
                  <i className="bi bi-circle"></i>
                  <span>Device&#39;s Log</span>
                </Link>
              </li>
            </ul>
          </li> */}
        </ul>
      </aside>
    </>
  );
};
